import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import { notification } from 'antd'
import { SessionActions } from '../actions/session'
import { getAccessToken } from '../utils/token'
import { ActionType } from '../context/AppReducer'
// import { getAccessToken } from '../utils/token'

export function applyAxiosInterceptors(dispatch: (action: ActionType) => void) {
  axios.interceptors.response.use(undefined, (error: any) => {
    if (error?.response?.status === 401 && _.includes(_.get(error, 'request.responseURL', ''), '/login')) {
      notification.error({
        message: 'Login Error',
        description: 'Credenziali errate.'
      })
      dispatch(SessionActions.closeSession())

      return undefined
    }

    if (error.response.status === 401 && !_.includes(_.get(error, 'request.responseURL', ''), '/login')) {
      notification.error({
        message: 'Authentication Error',
        description: 'non autenticato'
      })
      dispatch(SessionActions.closeSession())

      return undefined
    }

    if (error.response.status >= 500 && error.response.status < 600) {
      notification.error({
        message: 'Server Error',
        description: error.response.message
      })

      return undefined
    }
  })

  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = getAccessToken()
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  })
}

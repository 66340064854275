import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import { getAccessToken } from './utils/token';
// import { applyAxiosInterceptors } from './api/axios';

const REMOTE_SERVICE_URL = '/api'

axios.defaults.baseURL = `${REMOTE_SERVICE_URL}`
axios.defaults.withCredentials = true

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`
}

const tm = localStorage.getItem('kpi_theme') || 'dark'

ReactDOM.render(
  // <React.StrictMode>
  <ThemeSwitcherProvider themeMap={themes} defaultTheme={tm}>
    <App />
  </ThemeSwitcherProvider>,
  // </React.StrictMode>
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

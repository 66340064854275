import axios from 'axios'
import { Service } from '../context/types'

const baseUrl = '/services'

export const getServices = () => axios.get<Service[]>(`${baseUrl}`)

export const getServiceDetails = (sid: number, from: string, to: string) =>
  axios.get(`${baseUrl}/detail/?sid=${sid}&from=${from}&to=${to}`)

export const createService = (service: Service) => axios.post<Service>(`${baseUrl}`, service)

export const updateService = (service: Service) => axios.put<Service>(`${baseUrl}?sid=${service.sid}`, service)
import { useContext, useState } from 'react'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import format from 'date-fns/format'

import { Button, Collapse, Popover, Tooltip, DatePicker, Space } from 'antd'

import { SettingOutlined } from '@ant-design/icons'

import { AppLayout } from '../../components/Layout/AppLayout'

import { SearchKpi } from '../../components/SearchKpi/SearchKpi'
import { SelectLiveKpiServices } from '../../components/SelectServices/SelectLiveKpiServices'

import { GlobalContext } from '../../context/GlobalState'
import { DashboradActions } from '../../actions/dashboard'
import { SelectCategory } from '../../components/SelectCategory/SelectCategory'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { KpiContainerHistory } from '../../components/Kpi/KpiContainerHistory'
import { SelectCrServices } from '../../components/SelectServices/SelectCrServices'
import { ConversionRateHistory } from '../../components/ConversionRate/ConversionRateHistory'

const { Panel } = Collapse
const { RangePicker } = DatePicker

export const TitleBox = styled.div`
  position: fixed;
  top: 32px;
  width: calc(100vw - 170px);
  z-index: 999;
  background-color: transparent;

  /* background-color: rgb(54, 54, 54); */
  /* width: 100%; */
  padding: 6px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px 0 30px;

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
  }

  .ant-btn-round.ant-btn-sm {
    height: 18px;
    padding: 0px 12px;
    font-size: 10px;
    border-radius: 18px;
  }

  .ant-btn-link {
    color: #fff;
  }
`

export const IupayStatus = styled.div<{ $success: boolean }>`
  margin-left: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ $success }) => ($success ? 'green' : 'red')};
`

// const SectionWrapper = styled.div<{ $open: boolean }>`
//   height: ${({ $open }) => ($open ? 'unset' : '0px')} !important;
//   overflow: ${({ $open }) => ($open ? 'unset' : 'hidden')} !important; ;
// `

const BtnSelectServices = ({ panel, title = 'Select Services', styled = true }: { panel: JSX.Element; title?: string; styled?: boolean }) => {
  return (
    <>
      <Popover trigger="click" content={<span>{panel}</span>}>
        <Button
          icon={<SettingOutlined />}
          style={styled ? { float: 'right', zIndex: 90, top: '8px', right: ' 8px' } : {}}
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {title}
        </Button>
      </Popover>
    </>
  )
}

// const HOURS_INTERVAL = [1, 2, 4, 8, 12, 24, 48, 72]

export const DashboardHistoryPage = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const { dashboardStartDate, dashboardEndDate } = state

  const [startDate, setStartDate] = useState<string | null>(dashboardStartDate)
  const [endDate, setEndDate] = useState<string | null>(dashboardEndDate)

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  let withInterval = <span style={{ marginLeft: '30px' }}>Nessuna data impostata</span>
  if (dashboardStartDate !== null && dashboardEndDate !== null) {
    // withInterval = `(from: ${start} to: ${end})`
    withInterval = (
      <div className="with-interval">
        <div>
          <span className="interval-label">from: </span>
          <span className="interval-value">{startDate}</span>
        </div>
        <div>
          <span className="interval-label">to: </span>
          <span className="interval-value">{endDate}</span>
        </div>
      </div>
    )
  }

  const setLocalDates = (val: Moment[]) => {
    if (val) {
      setStartDate(val[0].format('yyyy-MM-DD'))
      setEndDate(val[1].format('yyyy-MM-DD'))
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }

  const setGlobalDates = () => {
    if (startDate === null || endDate === null) return
    dispatch(DashboradActions.setFilterDates(startDate, endDate))
  }

  return (
    <AppLayout>
      <TitleBox>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>DASHBOARD HISTORY</h2>
          <div style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
            <div>IUPAY status:</div>
            <Tooltip placement="bottom" title={'ultimo pixel: ' + format(state.iupayLastPixel, 'yyy-MM-dd HH:mm:ss')}>
              <IupayStatus $success={differenceInMinutes(new Date(), state.iupayLastPixel) < 5} />
            </Tooltip>
          </div>
        </div>

        <div>
          <Space>
            <RangePicker
              disabledDate={disabledDate}
              value={[startDate !== null ? moment(startDate) : null, endDate !== null ? moment(endDate) : null]}
              onChange={(val) => setLocalDates(val)}
            />

            <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => setGlobalDates()} />
          </Space>
        </div>
        <div></div>
        {/* <BtnSelectServices panel={<SectionSelector />} styled={false} title="Sections" /> */}
      </TitleBox>

      <BtnSelectServices panel={<SelectCrServices />} />
      <Collapse defaultActiveKey={['1']}>
        <Panel
          header={
            <div className="collapse-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div>Coversion Rate {withInterval}</div>
            </div>
          }
          key="1"
        >
          <span>{<ConversionRateHistory />}</span>
        </Panel>
      </Collapse>

      <br />

      <BtnSelectServices panel={<SelectLiveKpiServices />} />
      <SearchKpi />
      <SelectCategory />
      <Collapse defaultActiveKey={['1']}>
        <Panel header={<span className="collapse-title">KPI overview {withInterval}</span>} key="1">
          <span>{<KpiContainerHistory />}</span>
        </Panel>
      </Collapse>

      <br />
    </AppLayout>
  )
}

import axios from 'axios'
import { CidResponse, UserInfo, UserInfoResponse } from '../context/types'

export const getUserInfoOld = async (sid: number, cid: string): Promise<UserInfo[]> => {
  const res = await axios.get<UserInfoResponse>(`/cid?sid=${sid}&cid=${cid}`)
  const infos = []
  if (!res.data || res.data.result === false) return []
  Object.keys(res.data.resultQuery).forEach((k) => {
    infos.push({
      id: k,
      data: res.data.resultQuery[k]
    })
  })

  return infos
}
export const getUserInfo = async (sid: number | undefined, cid: string): Promise<{ data: CidResponse }> => {
  const res = await axios.get<CidResponse>(`/cid?${sid ? `sid=${sid}` : ''}&cid=${cid}`)
  // const infos = []
  // if (!res.data || res.data.result === false) return []
  // Object.keys(res.data.resultQuery).forEach((k) => {
  //   infos.push({
  //     id: k,
  //     data: res.data.resultQuery[k]
  //   })
  // })

  return res
}

import { ActionType } from '../context/AppReducer'

export const SET = 'socket/SET'
export const SET_LIVECHAT_SERVICES = 'socket/SET_LIVECHAT_SERVICES'

export const SocketActions = {
  setSockets: (socket: any): ActionType => {
    return {
      type: SET,
      payload: socket
    }
  },
  setLiveChatServices: (cr: number[]): ActionType => {
    return {
      type: SET_LIVECHAT_SERVICES,
      payload: cr
    }
  }
}

import {
  AppstoreOutlined,
  CalendarOutlined,
  DashboardOutlined,
  LineChartOutlined,
  LogoutOutlined,
  SearchOutlined,
  SettingOutlined,
  TagsOutlined,
  UserOutlined,
  BugOutlined
} from '@ant-design/icons'
import { Menu, Switch } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useHistory } from 'react-router'

import { SessionActions } from '../../actions/session'
import { GlobalContext } from '../../context/GlobalState'
import { Service } from '../../context/types'
import * as routes from '../../routes'

const { SubMenu } = Menu

export const SideMenu = () => {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const { switcher, status, themes } = useThemeSwitcher()

  const { state, dispatch } = useContext(GlobalContext)
  const history = useHistory()

  const tm = localStorage.getItem('kpi_theme') || 'dark'

  useEffect(() => {
    const isDark = tm && tm === 'dark'
    setIsDarkMode(isDark)
  }, [tm])

  if (!state || !state.services || state.services.length === 0) return null

  const activeServices = state.services.filter((s: Service) => s.status)

  const toggleTheme = (isChecked) => {
    setIsDarkMode(isChecked)
    localStorage.setItem('kpi_theme', isChecked ? 'dark' : 'light')
    switcher({ theme: isChecked ? themes.dark : themes.light })
  }

  const servicesMenuItems = activeServices.map((s) => (
    <Menu.Item
      key={`m-${s.name_canonical}-${s.sid}`}
      onClick={() => {
        history.push(`/service/${s.name_canonical}`)
      }}
    >
      <img src={`/images/${s.country}.png`} alt={s.country} width="16px" style={{ opacity: 0.8, marginRight: '4px' }} />
      {s.name}
    </Menu.Item>
  ))
  const servicesGAMenuItems = activeServices.map((s) => (
    <Menu.Item key={`g-${s.name_canonical}-${s.sid}`}>
      <img src={`/images/${s.country}.png`} alt={s.country} width="16px" style={{ opacity: 0.8, marginRight: '4px' }} />
      {s.name}
    </Menu.Item>
  ))

  // Avoid theme change flicker
  if (status === 'loading') {
    return null
  }

  const isAdmin = state.role === 'admin'

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '10px',
          padding: '10px 0',
          borderTop: '1px solid #303030'
        }}
      >
        {state.username}
      </div>
      <Menu defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} mode="inline" theme="dark">
        <Menu.Divider key="divider-1" />
        <Menu.Item key="dashboard" icon={<DashboardOutlined />} onClick={() => history.push('/')}>
          Dashboard
        </Menu.Item>

        <Menu.Item
          key="dashboard-history"
          icon={<CalendarOutlined />}
          onClick={() => {
            history.push('/dashboard-history')
          }}
        >
          Dashoard History
        </Menu.Item>
        {isAdmin && (
          <>
            <SubMenu key="services" icon={<AppstoreOutlined />}>
              {servicesMenuItems}
            </SubMenu>
          </>
        )}
        {isAdmin && (
          <Menu.Item key="pixel" icon={<TagsOutlined />} onClick={() => history.push('/pixel')}>
            Signup Pixels
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item key="debug" icon={<BugOutlined style={{ color: '#9d4e4e' }} />} onClick={() => history.push('/debug')}>
            Debug
          </Menu.Item>
        )}
        {isAdmin && (
          <SubMenu key="sub2" icon={<SearchOutlined />}>
            <Menu.Item
              key="7"
              icon={<UserOutlined />}
              onClick={() => {
                history.push(`${routes.SEARCH_USER}`)
              }}
            >
              Info User
            </Menu.Item>
          </SubMenu>
        )}
        {isAdmin && (
          <SubMenu key="graph" icon={<LineChartOutlined />}>
            {servicesGAMenuItems}
          </SubMenu>
        )}
        {isAdmin && (
          <SubMenu key="settings" icon={<SettingOutlined />}>
            <Menu.Item
              key="s-kpi"
              onClick={() => {
                history.push(`${routes.SETTINGS_KPI}`)
              }}
            >
              KPI
            </Menu.Item>
            <Menu.Item
              key="s-service"
              onClick={() => {
                history.push(`${routes.SETTINGS_SERVICES}`)
              }}
            >
              Service
            </Menu.Item>
          </SubMenu>
        )}
        <Menu.Divider key="divider-2" />
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => {
            dispatch(SessionActions.closeSession())
          }}
        >
          Logout
        </Menu.Item>
        <Menu.Divider key="divider-3" />
        {/* <Button onClick={() => history.push('/sdsdsd')}>TEST</Button> */}
      </Menu>
      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
        <Switch checked={isDarkMode} onChange={toggleTheme} />
      </div>
    </>
  )
}

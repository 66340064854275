import { useState } from 'react'
import { Layout, Card } from 'antd'
import styled from 'styled-components/macro'
import { MenuOutlined } from '@ant-design/icons'

import { SideMenu } from './SideMenu'

const { Header, Sider, Content, Footer } = Layout

export const Box = styled(Card)`
  &.ant-card {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    z-index: 1;
  }

  .mt40 {
    margin-top: 40px;
  }
`

export const AppLayout = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={true}
        className={isOpen ? 'sider-show' : ''}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0
        }}
      >
        <div className="logo">KPI</div>
        <SideMenu />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            position: 'fixed',
            top: 0,
            left: '78px',
            right: 0,
            zIndex: 100
          }}
        >
          <div className="mobile-menu" onClick={() => setIsOpen(!isOpen)}>
            <MenuOutlined />
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '84px 16px 0 100px',
            padding: '24px 24px 80px',
            minHeight: 280
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            bottom: 0,
            left: '80px',
            right: 0
          }}
        >
          KPI Panel ©2021-{new Date().getFullYear()} Created by Iumob Srl
        </Footer>
      </Layout>
    </Layout>
  )
}

import { useContext, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { Col, notification, Row, Tooltip } from 'antd'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { ChatCard, InfoBox } from './style'
import { SocketActions } from '../../actions/socket'
import { getChats } from '../../api/chats'
import { GlobalContext } from '../../context/GlobalState'
import { Service } from '../../context/types'

// const { Panel } = Collapse
const loadingSpin = <LoadingOutlined style={{ fontSize: 24 }} spin />

// const services = [
//     'IUMEET', 'LOOVEDATE', 'CALDE TENTAZIONI', 'INCONTRI GARANTITI', 'YOOPPE', 'MEET4TONIGHT', 'HOTDATINGMATCH', 'LOVEANDMEET', 'FLIRT AND MEETS',
//     'CALIENTES TENTACIONES', 'ENCUENTROS GARANTIZADOS', 'DESEO CALIENTE'
// ]

const serviceKeys = ['Client socket', 'Max invio', 'Media giornaliera', 'Media oraria', 'Server socket', 'limitAlerts']

type serviceDataType = 'Client socket' | 'Max invio' | 'Media giornaliera' | 'Media oraria' | 'Server socket' | 'limitAlerts'

export const ChatSocketContainer = () => {
  const { state, dispatch } = useContext(GlobalContext)
  const { sockets, services, liveChatServices } = state

  // const activeServices = services.filter((s: Service) => s.status && _.includes(liveChatServices, s.sid))

  const activeServices = useMemo(() => {
    return services.filter((s: Service) => s.status && _.includes(liveChatServices, s.sid))
  }, [liveChatServices, services])

  useEffect(() => {
    async function loadAllData() {
      try {
        activeServices.forEach(async (s: Service) => {
          if (s.status) {
            const resp = await getChats(s.sid)
            if (resp.status === 204) {
              dispatch(
                SocketActions.setSockets({
                  'Client socket': 0,
                  'Max invio': 0,
                  'Media giornaliera': 0,
                  'Media oraria': 0,
                  'Server socket': 0,
                  'Websocket attivi': 0,
                  serviceName: s.name,
                  status: 'unavailable',
                  limitAlerts: 0
                })
              )
            } else {
              console.log(resp.data)
              dispatch(SocketActions.setSockets(resp.data))
            }
          }
        })
      } catch (e) {
        notification.error({
          message: 'Socket Error',
          description: 'impossibile recuperare informazioni dei Socket'
        })
      }
    }
    let ti = undefined

    if (activeServices.length > 0) {
      console.log('ok')
      loadAllData()
      ti = setInterval(loadAllData, 30000)
    }

    return () => {
      if (ti) clearInterval(ti)
    }
  }, [dispatch, activeServices])

  // const uniqueServiceNames = Array.from(new Set(services.filter((s: Service) => s.status).map((ss: Service) => ss.name)))

  // const activeServices = []

  // for (let serviceName of uniqueServiceNames) {
  //   activeServices.push(services.find((s: Service) => s.name === serviceName))
  // }

  const displayData = activeServices.map((s: Service) => {
    const service = sockets.get(s.sid)
    if (!service) return null
    if (service.status === 'hidden') return null
    // console.log('Service found:', s.name)

    const body =
      service && service.status !== 'unavailable'
        ? serviceKeys.map((k: string) => (
            <InfoBox key={`${k}-bsi`}>
              <div className="body-service-info">
                <div className="body-service-info-label">{k}: </div>
                <div className="body-service-info-value">{service[k as serviceDataType]}</div>
              </div>
            </InfoBox>
          ))
        : undefined

    const titleBox = (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={`/images/${s.country}.png`} alt={s.country} width="16px" height="11px" style={{ opacity: 0.8, marginRight: '4px' }} />
          <span className="truncate">{s.name}</span>
          <Tooltip title={body || <span>No data</span>}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div style={{ position: 'relative' }}>
          <h2>{service ? (service.status !== 'unavailable' ? service['Websocket attivi'] : 'n/d') : loadingSpin}</h2>
          {service && service.alert && (
            <Tooltip title="Controllare Server">
              <div className="blob red" style={{ position: 'absolute', right: '16px', top: '1px' }}></div>
              {/* <div
                style={{
                  border: '1px solid #5a1010',
                  color: 'red',
                  position: 'absolute',
                  right: '20px',
                  top: '8px',
                  backgroundColor: '#ca2727',
                  borderRadius: '50%',
                  height: '12px',
                  width: '12px',
                  boxShadow: '0 0 4px 1px #d36246',
                  cursor: 'pointer'
                }}
              >
                &nbsp;
              </div> */}
            </Tooltip>
          )}
        </div>
      </>
    )

    return (
      <Col xs={12} sm={12} md={12} lg={8} xl={4} xxl={2} key={`${s.sid}-item`}>
        <ChatCard $alert={false} $status={service ? service.status : 'loading'} $loading={!service || service.status === 'unavailable'} title={titleBox}>
          {/* <Collapse>
            <Panel header="info" key={`${s}-more`}>
              <div>{body}</div>
            </Panel>
          </Collapse> */}
        </ChatCard>
      </Col>
    )
  })

  return (
    <div>
      <Row gutter={8}>{displayData}</Row>
    </div>
  )
}

import { useContext } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import format from 'date-fns/format'
import subHours from 'date-fns/subHours'
import { Button, Collapse, Popover, Tooltip } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import { AppLayout } from '../../components/Layout/AppLayout'
import { ChatSocket } from '../../components/ChatSocket'
import { KpiContainer } from '../../components/Kpi'
import { ConversionRate } from '../../components/ConversionRate/ConversionRate'
import { SelectCrServices } from '../../components/SelectServices/SelectCrServices'
import { SelectLiveChatServices } from '../../components/SelectServices/SelectLiveChatServices'
import { SearchKpi } from '../../components/SearchKpi/SearchKpi'
import { SelectLiveKpiServices } from '../../components/SelectServices/SelectLiveKpiServices'
import { SectionSelector } from '../../components/SectionSelector/SectionSelector'
import { GlobalContext } from '../../context/GlobalState'
import { DashboradActions } from '../../actions/dashboard'
import { SelectCategory } from '../../components/SelectCategory/SelectCategory'
import differenceInMinutes from 'date-fns/differenceInMinutes'

const { Panel } = Collapse

const TitleBox = styled.div`
  position: fixed;
  top: 32px;
  width: calc(100vw - 170px);
  z-index: 999;
  background-color: transparent;

  /* background-color: rgb(54, 54, 54); */
  /* width: 100%; */
  padding: 6px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px 0 30px;

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
  }

  .ant-btn-round.ant-btn-sm {
    height: 18px;
    padding: 0px 12px;
    font-size: 10px;
    border-radius: 18px;
  }

  .ant-btn-link {
    color: #fff;
  }
`

const IupayStatus = styled.div<{ $success: boolean }>`
  margin-left: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ $success }) => ($success ? 'green' : 'red')};
`

const SectionWrapper = styled.div<{ $open: boolean }>`
  height: ${({ $open }) => ($open ? 'unset' : '0px')} !important;
  overflow: ${({ $open }) => ($open ? 'unset' : 'hidden')} !important;
`

const BtnSelectServices = ({ panel, title = 'Select Services', styled = true }: { panel: JSX.Element; title?: string; styled?: boolean }) => {
  return (
    <>
      <Popover trigger="click" content={<span>{panel}</span>}>
        <Button
          icon={<SettingOutlined />}
          style={styled ? { float: 'right', zIndex: 90, top: '8px', right: ' 8px' } : {}}
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {title}
        </Button>
      </Popover>
    </>
  )
}

const HOURS_INTERVAL = [1, 2, 4, 8, 12, 24, 48, 72]

export const DashboardPage = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const { sections, rt_hours } = state

  // const changeInterval = (hours: number) => {
  //   const d = subHours(new Date(), hours)
  //   // setStartDate(format(d, 'yyyy-MM-dd HH:mm:ss'))
  // }

  const setHours = (hours: number) => {
    dispatch(DashboradActions.setRtHours(hours))
  }

  const SelectionHours = () => {
    return HOURS_INTERVAL.map((h: number) => (
      <Button key={`hour-${h}`} type={h === rt_hours ? 'primary' : 'link'} shape="round" size="small" onClick={() => setHours(h)}>
        {h}H
      </Button>
    ))
  }

  let withInterval = <span style={{ marginLeft: '30px' }}>Realtime</span>
  if (rt_hours > 0) {
    const start_date = format(subHours(new Date(), rt_hours), 'yyyy-MM-dd ')
    const start_hours = format(subHours(new Date(), rt_hours), 'HH:mm')
    const end_date = format(new Date(), 'yyyy-MM-dd')
    const end_hours = format(new Date(), 'HH:mm')

    // withInterval = `(from: ${start} to: ${end})`
    withInterval = (
      <div className="with-interval">
        <div>
          <span className="interval-label">from: </span>
          <span className="interval-value">
            {start_date}
            <span className="date-divider"></span>
            {start_hours}
          </span>
        </div>
        <div>
          <span className="interval-label">to: </span>
          <span className="interval-value">
            {end_date}
            <span className="date-divider"></span>
            {end_hours}
          </span>
        </div>
      </div>
    )
  }

  return (
    <AppLayout>
      <TitleBox>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>DASHBOARD</h2>
          <div style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
            <div>IUPAY: </div>
            {state.iupayLastPixel && (
              <Tooltip placement="bottom" title={'ultimo pixel: ' + format(state.iupayLastPixel, 'yyy-MM-dd HH:mm:ss')}>
                <IupayStatus $success={differenceInMinutes(new Date(), state.iupayLastPixel) < 5} />
              </Tooltip>
            )}
          </div>
          <div style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
            <div>MONGO:</div>
            <IupayStatus $success={state.pingError.mongo} />
          </div>
          <div style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
            <div>MYSQL:</div>
            <IupayStatus $success={state.pingError.mysql} />
          </div>
          <div style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
            <div>LOGS:</div>
            <IupayStatus $success={state.pingError.logs} />
          </div>
        </div>

        <div>
          <Button key={`hour-today`} type={rt_hours === 0 ? 'primary' : 'link'} shape="round" size="small" onClick={() => setHours(0)}>
            TODAY
          </Button>
          {SelectionHours()}
        </div>
        <BtnSelectServices panel={<SectionSelector />} styled={false} title="Sections" />
      </TitleBox>

      <SectionWrapper $open={_.includes(sections, 'Real Time Conversion Rate')}>
        <BtnSelectServices panel={<SelectCrServices />} />
        <Collapse defaultActiveKey={['1']}>
          <Panel
            header={
              <div className="collapse-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div>Coversion Rate {withInterval}</div>
              </div>
            }
            key="1"
          >
            <span>{<ConversionRate />}</span>
          </Panel>
        </Collapse>

        <br />
      </SectionWrapper>

      <SectionWrapper $open={_.includes(sections, 'Live Chat')}>
        <BtnSelectServices panel={<SelectLiveChatServices />} />
        <Collapse defaultActiveKey={['1']}>
          <Panel header={<span className="collapse-title">LIVE CHAT</span>} key="1">
            <span>{<ChatSocket />}</span>
          </Panel>
        </Collapse>

        <br />
      </SectionWrapper>

      <SectionWrapper $open={_.includes(sections, 'Real Time KPI overview')}>
        <BtnSelectServices panel={<SelectLiveKpiServices />} />
        <SearchKpi />
        <SelectCategory />
        <Collapse defaultActiveKey={['1']}>
          <Panel header={<span className="collapse-title">KPI overview {withInterval}</span>} key="1">
            <span>{<KpiContainer />}</span>
          </Panel>
        </Collapse>

        <br />
      </SectionWrapper>
    </AppLayout>
  )
}

import axios from 'axios'
import format from 'date-fns/format'
import { subHours } from 'date-fns/esm'
import { ConversionRate, ConversionRateHistoryResponse } from '../context/types'

// export const getConversionRates = async (sid: string, isForced: boolean) => await axios.get<ConversionRate>(`/crboxes?sid=${sid}${isForced ? '&force=1' : ''}`)

export const getConversionRates = async (sid: number, isForced: boolean, rt_hours: number) => {
  let withInterval = ''
  if (rt_hours > 0) {
    const start = format(subHours(new Date(), rt_hours), 'yyyy-MM-dd HH:mm:ss')
    const end = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    withInterval = `&date_start=${start}&date_end=${end}`
  }

  const rest = await axios.get<ConversionRate[]>(`/crboxes?sid=${sid}${isForced ? '&force=1' : ''}${withInterval}`)
  if (!rest || rest.status === 304) return { ...rest, status: 304 }
  return rest
}

export const getConversionRatesHistory = async (sid: number, startDate: string, endDate: string) =>
  await axios.get<ConversionRateHistoryResponse>(`/crboxes_history?sid=${sid}&force=1&date_start=${startDate}&date_end=${endDate}`)

import { Card, Col, Tooltip } from 'antd'
import styled from 'styled-components/macro'
import { DatabaseFilled, UserOutlined, StockOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ReactComponent as Mobile } from '../../assets/svgs/app.svg'
import { ReactComponent as Web } from '../../assets/svgs/web.svg'
import { ReactComponent as Splash2 } from '../../assets/svgs/promotion.svg'

const COLOR_BY_TYPE = {
  Signup: '#1c1cce9e',
  Payment: '#008000ba',
  Notifiche: '#ffff007a',
  Foto: '#00ffff70',
  Business: '#8000808f',
  Chat: '#ffa50078'
}
//     background-color: rgba(128,128,128,0.3) !important;

const KpiCard = styled(Card)<{ $isvalid: boolean; $alert: [colorMain: string, colorHead: string]; $interest: string }>`
  background-color: ${({ $alert }) => $alert[0]};
  margin-bottom: 20px !important;
  min-height: 150px;
  border-top: ${({ $interest }) => ($interest ? `4px solid ${COLOR_BY_TYPE[$interest]}` : '4px solid #888')};

  .ant-card-head {
    background-color: ${({ $alert }) => $alert[1]};
    font-size: 1.5em;
    font-weight: 600;
    min-height: 0;
    margin: 0;
    padding: 0;
    text-align: center;

    .ant-card-head-title {
      padding: 0;
    }
  }
  .ant-card-body {
    font-size: 12px !important;
    padding: 8px 4px;
    text-align: center;
    min-height: 100px;
    background-image: ${({ $isvalid }) =>
      $isvalid
        ? ''
        : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="rgba(128,128,128,0.3)"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>');`};

    background-repeat: no-repeat;
    background-position: center;
  }

  .ant-card-actions > li {
    margin: 4px 0;
  }
`
interface KpiItemProps {
  title: string
  body: string | JSX.Element
  alert: string
  type: string
  popup: JSX.Element
  interest: string
  category: string
}

const iconStyle = {
  fontSize: 14,
  color: '#888'
}

const KpiBoxItem = ({ title, body, alert, type, popup, interest, category }: KpiItemProps) => {
  const itemType = type === 'Server' ? <DatabaseFilled key="server" style={iconStyle} /> : type === 'Client' ? <UserOutlined key="client" style={iconStyle} /> : null

  const itemValue = title.split(' ')[0]
  const itemLabel = title.split(' ')[1]
  const itemExt = title.split(' ').length > 2 ? title.split(' ')[2].replace('mag', '> ').replace('minore', '< ') : undefined

  const fullTitle = (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', left: '8px', top: '0px' }}>
        {/* <img src={`/images/${category}.png`} style={{ width: '12px', height: 'auto', color: 'white' }} alt={category} /> */}
        <Tooltip title={category}>
          {category.toLowerCase() === 'web' && <Web fill="#5e555b" />}
          {category.toLowerCase() === 'splash2' && <Splash2 fill="#5e555b" />}
          {category.toLowerCase() === 'app' && <Mobile fill="#5e555b" />}
        </Tooltip>
      </div>
      <div style={{ color: alert === 'success' ? 'rgb(72,180,97)' : 'white' }}>
        <span>{itemValue}</span>
        <span style={{ marginLeft: '2px', fontSize: '12px' }}>{itemLabel}</span>
        {itemExt && <span style={{ marginLeft: '2px', fontSize: '10px' }}>{itemExt}</span>}
      </div>
      <div style={{ position: 'absolute', right: '8px', top: '-6px' }}>
        <Tooltip title={type}>{itemType}</Tooltip>
      </div>
    </div>
  )

  const alertCardColor = (alert: string) => {
    let colorMain = ''
    let colorHead = ''
    switch (alert) {
      case 'success':
        break
      case 'danger':
        colorMain = '#d03737 !important'
        colorHead = '#b70606 !important'
        break
      case 'warning':
        colorMain = '#c4b926 !important'
        colorHead = '#afa514 !important'
        break
      case 'nodata':
        colorMain = 'rgba(128,128,128,0.3) !important;'
        colorHead = 'rgba(128,128,128,0.25) !important;'
        break
      default:
        colorMain = 'transparent !important'
        colorHead = 'transparent !important'
        break
    }

    return [colorMain, colorHead]
  }

  return (
    <Col xs={12} sm={12} md={12} lg={8} xl={4} xxl={2}>
      <KpiCard
        title={fullTitle}
        $alert={[alertCardColor(alert)[0], alertCardColor(alert)[1]]}
        $interest={interest}
        $isvalid={alert !== 'nodata'}
        actions={[
          <StockOutlined key="graph" />,
          <Tooltip title={popup} placement="bottom">
            <InfoCircleOutlined key="info" />
          </Tooltip>
        ]}
      >
        {body}
      </KpiCard>
    </Col>
  )
}

export default KpiBoxItem

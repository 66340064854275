import { Select, Tag, Tooltip } from 'antd'
import _ from 'lodash'
import { useContext } from 'react'
import { KpiActions } from '../../actions/kpis'
import { GlobalContext } from '../../context/GlobalState'
// import { FilterOutlined, HighlightOutlined } from '@ant-design/icons'
import { KpiSetting } from '../../context/types'
// import { SelectLiveKpiServices } from '../SelectServices/SelectLiveKpiServices'
const { Option } = Select

interface ItemProps {
  label: string
  value: number
  description: string
  interest: string
  category: string
}

const mapInterestColor = {
  Signup: 'blue',
  Payment: 'green',
  Notifiche: 'yellow',
  Foto: 'cyan',
  Business: 'purple',
  Chat: 'orange'
}

export const mapCategory = {
  0: '',
  1: 'Splash2',
  2: 'Web',
  3: 'App'
}

function tagRender(props) {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  if (!label || !label.props.children) return null

  const interest = `${label.props.children.match(/[[].*?[\]]/gs)[0]}`.replace('[', '').replace(']', '')
  //   console.log(interest)

  return (
    <Tooltip title={label.props.children}>
      <Tag color={mapInterestColor[interest]} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label.props.children.split(':')[0]}
      </Tag>
    </Tooltip>
  )
}

export const SearchKpi = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const options: ItemProps[] = []
  _.orderBy(state.kpiSettings, (o) => o.interest)
    .filter((k: KpiSetting) => k.show_home)

    .forEach((k: KpiSetting) => {
      //   console.log(k)
      options.push({
        label: `${k.excel}: [${k.interest}] ${k.name}`,
        value: k.id,
        description: k.description,
        interest: k.interest,
        category: mapCategory[k.cat_id]
      })
    })

  const selectProps = {
    mode: 'multiple' as const,
    showSearch: true,
    style: { width: '400px' },
    value: state.filteredKpis,
    optionFilterProp: 'children',
    onChange: (newValue: number[]) => {
      dispatch(KpiActions.setFilterKpi(newValue))
    },
    placeholder: 'Select KPIs...',
    maxTagCount: 'responsive' as const,
    tagRender: tagRender
  }

  return (
    <div style={{ float: 'right', zIndex: 90, top: '8px', right: ' 8px', position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Select
          {...selectProps}
          filterOption={(input, option) => {
            const a = new RegExp(input, 'i')
            return a.test(option.label.toString()) || a.test(option.description)
          }}
        >
          {options.map((o: ItemProps) => (
            <Option value={o.value} label={`${o.label}-${o.category}`} key={o.value}>
              <div className={`ant-tag-${mapInterestColor[o.interest]}`}>{`${o.label}-${o.category}`}</div>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

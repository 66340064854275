import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { PlusOutlined, SettingOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Switch, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { KpiActions } from '../../actions/kpis'
import { createSettingsKpi, getSettingsKpi, updateSettingsKpi } from '../../api/settings'
import { AppLayout } from '../../components/Layout/AppLayout'
import { KpiTitle } from '../../components/Text/Title'
import { GlobalContext } from '../../context/GlobalState'
import { KpiSetting, KpiSid, Service } from '../../context/types'

const { Option } = Select
interface KpiSidActive extends KpiSid {
  active: boolean
  name: string
  id_settings_kpi: number
}

export const SettingsKPIPage = () => {
  const { state, dispatch } = useContext(GlobalContext)
  const { kpiSettings, kpiInfo, services } = state

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [kpiServices, setKpiServices] = useState<KpiSidActive[]>([])

  const [step, setStep] = useState(0)
  const [isError, setIsError] = useState(false)

  const [selectedSetting, setSelectedSetting] = useState<KpiSetting>()
  const [form] = Form.useForm()

  const kpiParamsList = kpiInfo.kpi

  const kpiCatMap = {
    1: 'Splash2',
    2: 'Web',
    3: 'App'
  }

  useEffect(() => {
    if (selectedSetting) form.resetFields()
  }, [selectedSetting, form])

  const colSize = selectedSetting && selectedSetting.id === 11 ? 3 : 4

  const columns: ColumnsType<KpiSetting> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Excel',
      dataIndex: 'excel',
      key: 'excel',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.excel - b.excel,
      defaultSortOrder: 'descend',
      width: 50
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Param 1',
      dataIndex: 'param1',
      key: 'param1',
      render: (id: number) => <div>{kpiParamsList.find((k) => k.id === id)?.name || ''}</div>
    },
    {
      title: 'Param 2',
      dataIndex: 'param2',
      key: 'param2',
      render: (id: number) => <div>{kpiParamsList.find((k) => k.id === id)?.name || ''}</div>
    },
    {
      title: 'Functions',
      dataIndex: 'functions',
      key: 'functions'
    },
    {
      title: 'Cat',
      dataIndex: 'cat_id',
      key: 'cat_id',
      render: (cat_id: number | null) => <div>{cat_id ? kpiCatMap[cat_id] : '...'}</div>
    },
    {
      title: 'Show',
      dataIndex: 'show_home',
      render: (_: string, record: KpiSetting) => (
        <>
          <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={record.show_home} onChange={() => onCreate({ ...record, show_home: !record.show_home })} />
        </>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: string, record: KpiSetting) => (
        <>
          <Button onClick={() => showModal(record)}>Edit</Button>
        </>
      )
    }
  ]

  const showModal = (record?: KpiSetting) => {
    if (!record) {
      record = {
        setting: undefined,
        cat_id: null,
        name: undefined,
        description: undefined,
        limits: undefined,
        functions: undefined,
        param1: undefined,
        param2: undefined,
        unit: undefined,
        show_home: true,
        type: undefined,
        interest: undefined,
        excel: undefined,
        id_settings_kpi: undefined,
        sids: []
      }
    }
    setSelectedSetting(record)
    setIsModalVisible(true)
    const newKpiSids = services.map((s: Service) => {
      const serv = _.find(record.sids, ['sid', s.sid])
      if (!!serv) {
        return {
          ...serv,
          active: true,
          name: `${s.country} - ${s.name}`,
          id_settings_kpi: selectedSetting?.id || 0
        }
      }
      return {
        sid: s.sid,
        limits_warning: 0,
        limits_danger: 0,
        set_min_max: '<',
        setting: 0,
        active: false,
        name: `${s.country} - ${s.name}`,
        limits_alert: 0,
        id_settings_kpi: 0
      }
    })
    setKpiServices(newKpiSids)
    // console.log(record.sids)
  }

  const handleCancel = () => {
    setSelectedSetting(undefined)
    // form.resetFields()
    setIsModalVisible(false)
  }
  const onCreate = async (values: KpiSetting) => {
    const resp = values.id ? await updateSettingsKpi(values) : await createSettingsKpi(values)
    console.log('onCreate', resp)
    if (resp?.status >= 200 && resp.status < 300) {
      notification.success({
        message: 'OK',
        description: 'KPI aggiornato correttamente'
      })
      await getSettingsKpi().then((resp) => dispatch(KpiActions.setKpiSettings(resp.data)))
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()

        const newSids = kpiServices
          .filter((k: KpiSidActive) => k.active)
          .map((k: KpiSidActive) => {
            return {
              ...k,
              limits_alert: k.limits_alert || 0
            }
          })
        // onCreate({ ...selectedSetting, ...values })
        onCreate({ ...selectedSetting, ...values, sids: [...newSids] })
        // console.log(selectedSetting)
        // console.log(values)
        // console.log(JSON.stringify({ ...selectedSetting, ...values, sids: [...newSids] }, null, 2))
        setIsModalVisible(false)
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  const kpiFunctions = kpiInfo.functions.map((kf) => (
    <Option key={kf} value={kf}>
      {kf}
    </Option>
  ))

  const kpiParams = kpiInfo.kpi.map((kp) => (
    <Option key={kp.id} value={kp.id}>
      {kp.name}
    </Option>
  ))

  const kpiTypes = ['Server', 'Client'].map((kp) => (
    <Option key={kp} value={kp}>
      {kp}
    </Option>
  ))

  const kpiInterests = ['Signup', 'Foto', 'Chat', 'Mail', 'Notifiche', 'Payment'].map((kp) => (
    <Option key={kp} value={kp}>
      {kp}
    </Option>
  ))

  const updateKpiService = (sid: number, field: string, value: any) => {
    const newKpiServices = kpiServices.map((k: KpiSidActive) => {
      if (k.sid === sid) return { ...k, [field]: value, id_settings_kpi: selectedSetting.id }
      return k
    })

    setKpiServices(newKpiServices)
  }

  const toggleKpiService = (sid: number) => {
    const newKpiServices = kpiServices.map((k: KpiSidActive) => {
      if (k.sid === sid) return { ...k, active: !k.active }
      return k
    })

    setKpiServices(newKpiServices)
  }

  const availableServices = kpiServices.map((k: KpiSidActive) => {
    return (
      <Row key={k.sid} gutter={16}>
        <Col span={8}>
          <Checkbox checked={k.active} onChange={() => toggleKpiService(k.sid)} />
          <span style={{ marginLeft: '8px', fontSize: '14px' }}>{k.name}</span>
        </Col>
        <Col span={colSize}>
          <InputNumber
            className={colSize === 3 ? 'size-sm' : ''}
            value={k.limits_warning}
            step={1}
            min={0}
            max={100}
            width={60}
            onChange={(value) => updateKpiService(k.sid, 'limits_warning', value)}
          />
        </Col>
        <Col span={colSize}>
          <InputNumber
            className={colSize === 3 ? 'size-sm' : ''}
            value={k.limits_danger}
            step={1}
            min={0}
            max={100}
            width={60}
            onChange={(value) => updateKpiService(k.sid, 'limits_danger', value)}
          />
        </Col>
        <Col span={colSize}>
          <Select style={{ width: '100%' }} value={k.set_min_max} onChange={(value) => updateKpiService(k.sid, 'set_min_max', value)}>
            <Option value=">">maggiore</Option>
            <Option value="<">minore</Option>
          </Select>
        </Col>
        <Col span={colSize}>
          <InputNumber
            className={colSize === 3 ? 'size-sm' : ''}
            value={k.setting}
            step={1}
            min={0}
            max={100}
            width={60}
            onChange={(value) => updateKpiService(k.sid, 'setting', value)}
          />
        </Col>
        {colSize === 3 && (
          <Col span={colSize}>
            <InputNumber className={'size-sm'} value={k.limits_alert} step={1} min={0} max={100} width={60} onChange={(value) => updateKpiService(k.sid, 'limits_alert', value)} />
          </Col>
        )}
      </Row>
    )
  })

  return (
    <AppLayout>
      <KpiTitle size="XL">
        KPI
        <Button icon={<PlusOutlined />} style={{ float: 'right' }} onClick={() => showModal()}>
          Nuovo
        </Button>
      </KpiTitle>

      <Table dataSource={kpiSettings} columns={columns} rowKey={'id'} pagination={{ pageSize: 50 }}></Table>
      {selectedSetting && (
        <Modal
          style={{ minWidth: '800px', width: '30%' }}
          title={`${selectedSetting.id ? 'Update' : 'Create new'} Service ${selectedSetting.name || ''}`}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" disabled={isError} onClick={handleOk}>
              {`${selectedSetting.id ? 'Update' : 'Save'}`}
            </Button>
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={selectedSetting}
            onFieldsChange={() => {
              setIsError(!!form.getFieldsError().filter(({ errors }) => errors.length).length)
            }}
          >
            <div className={step === 0 ? 'step-show' : 'step-hide'}>
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="type" label="Type">
                    <Select value={selectedSetting.type} placeholder="Select a type">
                      <Option key="nothing" value="">
                        Nessuno
                      </Option>
                      {kpiTypes}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="interest" label="Interest">
                    <Select value={selectedSetting.interest} placeholder="Select an Interest">
                      <Option key="nothing" value="">
                        Nessuno
                      </Option>
                      {kpiInterests}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row gutter={16}>
                <Col span={8}>
                  <Form.Item name="limits" label="Max limit alert">
                    <InputNumber min={0} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="unit" label="Unit">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="setting" label="Max Value">
                    <Input />
                  </Form.Item>
                </Col>
              </Row> */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="functions" label="Functions">
                    <Select value={selectedSetting.functions}>{kpiFunctions}</Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="cat_id" label="Category">
                    <Select value={selectedSetting.cat_id}>
                      <Option key={0} value={0}>
                        Nessuna
                      </Option>
                      <Option key={1} value={1}>
                        Splash2
                      </Option>
                      <Option key={2} value={2}>
                        Web
                      </Option>
                      <Option key={3} value={3}>
                        App
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="param1" label="Param 1" rules={[{ required: true, message: 'Please input the param1' }]}>
                    <Select value={selectedSetting.param1} placeholder="Select a param">
                      {kpiParams}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="param2" label="Param 2">
                    <Select value={selectedSetting?.param2 || 0} defaultValue={0}>
                      <Option key="0" value={0}>
                        nessuno
                      </Option>
                      {kpiParams}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={step === 1 ? 'step-show' : 'step-hide'}>
              <Row gutter={16}>
                <Col span={colSize} offset={8} style={{ paddingLeft: '20px' }}>
                  Warning
                </Col>
                <Col span={colSize} style={{ paddingLeft: '20px' }}>
                  Danger
                </Col>
                <Col span={colSize} style={{ paddingLeft: '20px' }}>
                  test
                </Col>
                <Col span={colSize} style={{ paddingLeft: '20px' }}>
                  Setting
                </Col>
              </Row>
              {availableServices}
              <br />
            </div>
            <Row>
              <Col span={24}>
                {step === 0 && (
                  <Button type="ghost" onClick={() => setStep(1)} icon={<SettingOutlined />}>
                    Setup Services
                  </Button>
                )}
                {step === 1 && (
                  <Button type="ghost" onClick={() => setStep(0)} icon={<LeftOutlined />}>
                    Setup Kpi
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </AppLayout>
  )
}

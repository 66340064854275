import { PageHeader } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { KpiActions } from '../../actions/kpis'
import { getServiceKpis } from '../../api/kpis'
import { KpiRow } from '../../components/Kpi/KpiRow'
import { AppLayout } from '../../components/Layout/AppLayout'
import { ServiceTitle } from '../../components/ServiceTitle/ServiceTitle'
import { KpiTitle } from '../../components/Text/Title'
import { GlobalContext } from '../../context/GlobalState'

export const ServicePage = () => {
  let { serviceName }: { serviceName: string } = useParams()

  const { state, dispatch } = useContext(GlobalContext)
  const { services, serviceKpis, rt_hours } = state
  const service = services.find((s) => s.name_canonical === serviceName)

  useEffect(() => {
    let force = true
    async function loadAllData() {
      if (!service) return
      getServiceKpis(service.sid, force, rt_hours).then((resp) => {
        if (resp.status === 200) {
          dispatch(KpiActions.setKpiPanel(resp.data))
        }
      })

      force = false
    }

    loadAllData()
    let ti = setInterval(loadAllData, 20000)
    return () => {
      if (ti) clearInterval(ti)
    }
  }, [dispatch, service, rt_hours])

  if (!service) return null
  const serviceKpi = serviceKpis.get(service.sid)

  return (
    <AppLayout>
      <KpiTitle size="L">
        <ServiceTitle service={service} isLoading={state.isLoadingKpis.get(service.sid)} />
      </KpiTitle>
      <PageHeader className="site-page-header" title="Kpis" style={{ marginTop: '40px' }} />
      {service && serviceKpi && <KpiRow service={service} serviceKpi={serviceKpi} />}
    </AppLayout>
  )
}

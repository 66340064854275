import React from 'react'
import { Spin, Tooltip } from 'antd'
// import { ChromeOutlined, MobileOutlined } from '@ant-design/icons'

import { Service } from '../../context/types'
import styled from 'styled-components/macro'

const StyledTitle = styled.span`
  .service-title-wrapper {
    display: flex;
    align-items: center;
    min-height: 24px;
  }

  .ant-spin-text {
    color: #fff;
  }

  .ant-spin-dot-item {
    background-color: #fff;
  }
`

interface ServiceTitleProps {
  service: Service
  isLoading: boolean
}

export const ServiceTitle = ({ service, isLoading }: ServiceTitleProps) => {
  return (
    <StyledTitle>
      <div className="service-title-wrapper">
        <Tooltip title={service.country}>
          <img src={`/images/${service.country}.png`} alt={service.country} width="20px" style={{ opacity: 0.7, marginRight: '16px' }} />
        </Tooltip>
        {/* <span style={{ marginLeft: '16px', marginRight: '16px' }}>
          {service.channel.toUpperCase() === 'WEB' ? (
            <Tooltip title="channel: WEB">
              <ChromeOutlined style={{ color: '#888' }} />
            </Tooltip>
          ) : (
            <Tooltip title="channel: APP">
              <MobileOutlined style={{ color: '#888' }} />
            </Tooltip>
          )}
        </span> */}

        <span>
          {service.name}{' '}
          {isLoading ? (
            <span style={{ marginLeft: '10px' }}>
              <Spin size="small" />
            </span>
          ) : null}
        </span>
      </div>
    </StyledTitle>
  )
}

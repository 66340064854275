import { ReactNode } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components/macro'

const { Text } = Typography

export type Size = 'S' | 'M' | 'L' | 'XL' | number

type SizeProps = {
    fontSize: string
    lineHeight: number
}

const SIZES: Record<Size, SizeProps> = {
    S: { fontSize: '12px', lineHeight: 1.5 },
    M: { fontSize: '16px', lineHeight: 1.71 },
    L: { fontSize: '24px', lineHeight: 1.33 },
    XL: { fontSize: '32px', lineHeight: 1.5 }
}

export const StyledTitle = styled(Text) <{ size: Size, isbold: string }>`
  ${({ size }) => SIZES[size || 'M']};
  font-weight: ${({ isbold }) => isbold} !important;
  margin-bottom: 1em;
`

export type TitleProps = {
    size: Size
    bold?: boolean
    text?: string
    children?: ReactNode | ReactNode[]
}

export const KpiTitle = ({ size, bold = true, children, text, ...props }: TitleProps) => {
    return (
        <StyledTitle size={size} isbold={bold ? 'bold' : 'normal'} {...props}>
            {children || text}
        </StyledTitle>
    )
}
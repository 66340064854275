import axios from 'axios'
import format from 'date-fns/format'
import { subHours } from 'date-fns/esm'
import { KpiHistoryNotAdmin, KpiInfo, PingError, ServiceKpis } from '../context/types'
// import { subMinutes } from 'date-fns'

export const getServiceKpis = async (sid: number, isForced: boolean, rt_hours: number) => {
  let withInterval = ''
  if (rt_hours > 0) {
    const start = format(subHours(new Date(), rt_hours), 'yyyy-MM-dd HH:mm:ss')
    const end = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    withInterval = `&date_start=${start}&date_end=${end}`
  }
  const rest = await axios.get<ServiceKpis>(`/dashboard?sid=${sid}${isForced ? '&force=1' : ''}${withInterval}`)

  let kpis = []
  // the object title could contain 3 subObject, this code flattens the array
  if (!rest || !rest.data.kpis || rest.status === 304) return { ...rest, status: 304 }
  rest.data.kpis.forEach((k) => {
    if (typeof k.title === 'object') {
      kpis = [
        ...kpis,
        ...Object.keys(k.title).map((key) => ({
          ...k,
          title: `${k.title[key]} ${key}`,
          id: `${key}_${k.id}`
        }))
      ]
    } else if (!k.title) {
      kpis.push({ ...k, title: 'n/d' })
    } else {
      kpis.push(k)
    }
  })
  rest.data.kpis = kpis
  return rest
}

export const getServiceKpisHistoryNotAdmin = async (sid: number, startDate: string, endDate: string) => {
  const rest = await axios.get<KpiHistoryNotAdmin>(`/dashboard_history?sid=${sid}&force=1&date_start=${startDate}&date_end=${endDate}`)
  if (!rest || !rest.data.kpis || rest.status === 304) return { ...rest, status: 304 }

  return rest
}

export const getServiceKpisHistory = async (sid: number, startDate: string, endDate: string) => {
  const rest = await axios.get<ServiceKpis>(`/dashboard_history?sid=${sid}&force=1&date_start=${startDate}&date_end=${endDate}`)
  let kpis = []
  // the object title could contain 3 subObject, this code flattens the array
  if (!rest || !rest.data.kpis || rest.data.kpis.length === 0 || rest.status === 304) return { ...rest, status: 304 }

  rest.data.kpis.forEach((k) => {
    if (typeof k.title === 'object') {
      kpis = [
        ...kpis,
        ...Object.keys(k.title).map((key) => ({
          ...k,
          title: `${k.title[key]} ${key}`,
          id: `${key}_${k.id}`
        }))
      ]
    } else if (!k.title) {
      kpis.push({ ...k, title: 'n/d' })
    } else {
      kpis.push(k)
    }
  })
  rest.data.kpis = kpis
  return rest
}

export const getKpiInfo = () => axios.get<KpiInfo>('/kpi_info')

export const getIupayLastPixel = () => axios.get<Date>('/lastpingiupay')
export const getLastPing = () => axios.get<PingError>('/lastping_error')

// {
//   const rnd = Math.floor(Math.random() * 10)
//   const today = new Date()
//   const newDate = subMinutes(today, 10)
//   if (rnd > 5) return newDate
//   return today

//   // axios.get<Date>('/lastpingiupay')
// }

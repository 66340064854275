import { Checkbox, Divider } from 'antd'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { ServicesActions } from '../../actions/services'
// import { SocketActions } from '../../actions/socket'
import { GlobalContext } from '../../context/GlobalState'
import { Service } from '../../context/types'

const CheckboxGroup = Checkbox.Group

// const plainOptions = ['Apple', 'Pear', 'Orange']
// const defaultCheckedList = ['Apple', 'Orange']

const StyledCheckboxGroup = styled(CheckboxGroup)`
  .ant-checkbox-wrapper.ant-checkbox-group-item {
    display: flex;
  }

  .ant-checkbox-wrapper {
    display: block;
    margin-right: 0;
  }
`

export const SelectLiveKpiServices = () => {
  // const [crServices, setCrServices] = useState<number[]>([])

  const { state, dispatch } = useContext(GlobalContext)

  const { services } = state

  const [checkedList, setCheckedList] = useState<string[]>([])
  const [plainOptions, setPlainOptions] = useState<string[]>([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  useEffect(() => {
    setPlainOptions(services.map((s: Service) => `${s.country} - ${s.name}`))
    const getLiveKpiServices = localStorage.getItem('kpiServices')
    if (getLiveKpiServices) {
      const objLiveKpiServices = JSON.parse(getLiveKpiServices)
      const selectedServices = services.filter((s: Service) => _.includes(objLiveKpiServices, s.sid)).map((s: Service) => `${s.country} - ${s.name}`)
      setCheckedList(selectedServices)
      if (selectedServices.length === services.length) {
        setCheckAll(true)
        setIndeterminate(false)
      }
    }
  }, [services])

  const onChange = (list) => {
    setCheckedList(list)
    // console.log(list)
    const selectedSids = list.map((str: string) => {
      const country = str.split(' - ')[0]
      const serviceName = str.split(' - ')[1]
      const service = services.find((s: Service) => s.name === serviceName && country === s.country)
      return service.sid
    })

    // console.log(selectedSids)
    dispatch(ServicesActions.setLiveKpiServices(selectedSids))
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e) => {
    e.stopPropagation()
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
    dispatch(ServicesActions.setLiveKpiServices(e.target.checked ? services.map((s: Service) => s.sid) : []))
  }

  return (
    <div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <Divider />
      <StyledCheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
    </div>
  )
}

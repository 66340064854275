import { PlusOutlined, SettingOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Switch, Table } from 'antd'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { ServicesActions } from '../../actions/services'
import { createService, getServices, updateService } from '../../api/services'
import { AppLayout } from '../../components/Layout/AppLayout'
import { KpiTitle } from '../../components/Text/Title'
import { GlobalContext } from '../../context/GlobalState'
import { Country, KpiSetting, Service, ServiceKpiSetting } from '../../context/types'

const { Option } = Select

interface KpiActive extends ServiceKpiSetting {
  active: boolean
  name: string
}

export const SettingsServicesPage = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const { services, countries, kpiSettings } = state
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [step, setStep] = useState(0)
  const [isError, setIsError] = useState(false)

  const [serviceKpis, setServiceKpis] = useState<KpiActive[]>([])

  const [selectedService, setSelectedService] = useState<Service>()
  const [form] = Form.useForm()

  useEffect(() => {
    if (selectedService) form.resetFields()
  }, [selectedService, form])

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: string, s: Service) => <img src={`/images/${s.country}.png`} alt={s.country} width="16px" style={{ opacity: 0.8 }} />
    },
    {
      title: 'url',
      dataIndex: 'url',
      key: 'url'
    },
    {
      title: 'IUChat',
      dataIndex: 'iuchat',
      key: 'iuchat'
    },
    {
      title: 'Analytics',
      dataIndex: 'view_id',
      key: 'view_id'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: string, record: Service) => (
        <>
          <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={record.status} onChange={() => onCreate({ ...record, status: !record.status })} />
        </>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: string, record: Service) => (
        <>
          <Button onClick={() => showModal(record)}>Edit</Button>
        </>
      )
    }
  ]

  const showModal = (record?: Service) => {
    if (!record) {
      // console.log('no record found')
      record = {
        sid: undefined,
        name: undefined,
        name_canonical: undefined,
        country: undefined,
        url: undefined,
        // channel: undefined,
        iuchat: undefined,
        status_update: undefined,
        status: true,
        created_at: undefined,
        view_id: undefined,
        kpis: []
      }
    }
    setSelectedService(record)
    setIsModalVisible(true)

    const newKpis = kpiSettings.map((k: KpiSetting) => {
      // console.log(kpiSettings)
      const serv = _.find(record.kpis, ['id_settings_kpi', k.id])

      if (!!serv) {
        // console.log('found id: ', k.id)
        return {
          ...serv,
          name: k.name,
          active: true
        }
      }
      // console.log('NOT found id: ', k.id)
      return {
        id: k.id,
        limits_warning: 0,
        limits_danger: 0,
        set_min_max: '<',
        setting: 0,
        name: k.name,
        limits_alert: 0,
        id_settings_kpi: k.id,
        active: false
      }
    })

    setServiceKpis(newKpis)

    // console.log(serviceKpis)
  }

  const onCreate = async (values: Service) => {
    if (!values.name_canonical) {
      values.name_canonical = values.name.trim().toLowerCase().replaceAll(' ', '')
    }
    const resp = values.sid ? await updateService(values) : await createService(values)
    if (resp?.status >= 200 && resp.status < 300) {
      notification.success({
        message: 'OK',
        description: 'Service aggiornato correttamente'
      })
      await getServices().then((resp) => dispatch(ServicesActions.setServices(resp.data)))
    }
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()

        const newKpis = serviceKpis
          .filter((k: KpiActive) => k.active)
          .map((k: KpiActive) => {
            return {
              ...k,
              limits_alert: k.limits_alert || 0,
              sid: selectedService.sid
            }
          })

        onCreate({ ...selectedService, ...values, kpis: [...newKpis] })

        // console.log(JSON.stringify({ ...selectedService, ...values, kpis: [...newKpis] }, null, 2))
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const updateServiceKpi = (id_settings_kpi: number, field: string, value: any) => {
    const newKpis = serviceKpis.map((k: KpiActive) => {
      if (k.id_settings_kpi === id_settings_kpi) return { ...k, [field]: value }
      return k
    })

    setServiceKpis(newKpis)
  }

  const toggleServiceKpi = (id_settings_kpi: number) => {
    const newKpis = serviceKpis.map((k: KpiActive) => {
      if (k.id_settings_kpi === id_settings_kpi) return { ...k, active: !k.active }
      return k
    })

    setServiceKpis(newKpis)
  }

  const countriesOptions = countries.map((c: Country) => (
    <Option key={c.name} value={c.name}>
      {c.name}
    </Option>
  ))

  const availableKpis = serviceKpis.map((k: KpiActive) => {
    return (
      <Row key={`${k.name}-${k.id}`} gutter={16}>
        <Col span={8}>
          <Checkbox checked={k.active} onChange={() => toggleServiceKpi(k.id_settings_kpi)} />
          <span style={{ marginLeft: '8px', fontSize: '14px' }}>{k.name}</span>
        </Col>
        <Col span={3}>
          <InputNumber
            className={'size-sm'}
            value={k.limits_warning}
            step={1}
            min={0}
            max={100}
            width={60}
            onChange={(value) => updateServiceKpi(k.id_settings_kpi, 'limits_warning', value)}
          />
        </Col>
        <Col span={3}>
          <InputNumber
            className={'size-sm'}
            value={k.limits_danger}
            step={1}
            min={0}
            max={100}
            width={60}
            onChange={(value) => updateServiceKpi(k.id_settings_kpi, 'limits_danger', value)}
          />
        </Col>
        <Col span={3}>
          <Select style={{ width: '100%' }} value={k.set_min_max} onChange={(value) => updateServiceKpi(k.id_settings_kpi, 'set_min_max', value)}>
            <Option value=">">maggiore</Option>
            <Option value="<">minore</Option>
          </Select>
        </Col>
        <Col span={3}>
          <InputNumber className={'size-sm'} value={k.setting} step={1} min={0} max={100} width={60} onChange={(value) => updateServiceKpi(k.id_settings_kpi, 'setting', value)} />
        </Col>
        {k.name === 'Socket' && (
          <Col span={3}>
            <InputNumber
              className={'size-sm'}
              value={k.limits_alert}
              step={1}
              min={0}
              max={100}
              width={60}
              onChange={(value) => updateServiceKpi(k.id_settings_kpi, 'limits_alert', value)}
            />
          </Col>
        )}
      </Row>
    )
  })

  return (
    <AppLayout>
      <KpiTitle size="XL">
        Servizi{' '}
        <Button icon={<PlusOutlined />} style={{ float: 'right' }} onClick={() => showModal()}>
          Nuovo
        </Button>
      </KpiTitle>
      <Table dataSource={services} columns={columns} rowKey={'sid'} pagination={{ pageSize: 50 }}></Table>
      {selectedService && (
        <Modal
          style={{ minWidth: '900px', width: '40%' }}
          title={`${selectedService.sid ? 'Update' : 'Create new'} Service ${selectedService.name || ''}`}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" disabled={isError} onClick={handleOk}>
              {`${selectedService.sid ? 'Update' : 'Save'}`}
            </Button>
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={selectedService}
            onFieldsChange={() => {
              setIsError(!!form.getFieldsError().filter(({ errors }) => errors.length).length)
            }}
          >
            <div className={step === 0 ? 'step-show' : 'step-hide'}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name of Service' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                    <Select value={selectedService.country}>{countriesOptions}</Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="url" label="Url" rules={[{ required: true, message: 'Please input the url of Service' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="iuchat" label="IUChat" rules={[{ required: true, message: 'Please input the iuchat of Service' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="view_id" label="GA id">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={step === 1 ? 'step-show step-scroll' : 'step-hide'}>
              <Row gutter={16}>
                <Col span={3} offset={8} style={{ paddingLeft: '20px' }}>
                  Warning
                </Col>
                <Col span={3} style={{ paddingLeft: '20px' }}>
                  Danger
                </Col>
                <Col span={3} style={{ paddingLeft: '20px' }}>
                  test
                </Col>
                <Col span={3} style={{ paddingLeft: '20px' }}>
                  Setting
                </Col>
                <Col span={3} style={{ paddingLeft: '20px' }}>
                  Socket
                </Col>
              </Row>
              {availableKpis}
              <br />
            </div>
            <Row>
              <Col span={24}>
                {step === 0 && (
                  <Button type="ghost" onClick={() => setStep(1)} icon={<SettingOutlined />}>
                    Setup Kpis
                  </Button>
                )}
                {step === 1 && (
                  <Button type="ghost" onClick={() => setStep(0)} icon={<LeftOutlined />}>
                    Setup Service
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </AppLayout>
  )
}

import { Button, Form, Input, Space } from 'antd';
import { useContext } from 'react';
import styled from 'styled-components/macro';
import { SessionActions } from '../../actions/session';
import { loginUser } from '../../api/user';
import { Title } from '../../components/Text';
import { GlobalContext } from '../../context/GlobalState';
import { setAccessToken } from '../../utils/token';



const LoginWrapper = styled.div`
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const LoginForm = styled(Form)`
  width: 400px;
    padding: 30px 40px !important;
    background-color: #151515;
    border-radius: 4px;
  .login-form-button {
    width: 100%;
  }
  .ant-form-item-children {
    display: flex;
  }
  .form-error {
    position: absolute;
    margin-top: 20px;
    font-size: 12px;
    color: #ff3c65;
  }
`

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { sm: { offset: 0 }, md: { offset: 8, span: 16 } }
};

export const LoginPage = () => {

    const { dispatch } = useContext(GlobalContext)

    const onFinish = async (values: any) => {
        const resp = await loginUser(values.username, values.password)
        if (resp) {
            setAccessToken(resp.data.token)
            dispatch(SessionActions.setSession({
                token: resp.data.token,
                refreshToken: 'xxxxxaxaxaxaxax' // TODO
            }))
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        // TODO manage errors
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            <LoginWrapper>
                <span style={{ marginBottom: '20px' }}>
                    <Space>
                        <Title size="XL" >KPI</Title>
                        <Title size="XL" bold={false} >Panel</Title>
                    </Space>

                </span>
                <LoginForm
                    {...layout}
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" block>
                            Submit
                        </Button>
                    </Form.Item>
                </LoginForm>
            </LoginWrapper>
        </>
    )
}
import { ActionType } from "../context/AppReducer"

export const CLOSE = 'session/CLOSE'
export const SET = 'session/SET'
export const RESTORE = 'session/RESTORE'

export const SessionActions = {
    setSession: (session: any): ActionType => {
        return {
            type: 'session/SET',
            payload: session
        }
    },
    restoreSession: (): ActionType => {
        return {
            type: 'session/RESTORE',
            payload: null
        }
    },
    closeSession: (): ActionType => {
        return {
            type: 'session/CLOSE',
            payload: {}
        }
    }
}
import { Space, DatePicker, Select, Button, Table, Modal, Spin, Popover } from 'antd'
import moment, { Moment } from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'

import { DebugdActions } from '../../actions/debug'
import { debugMe } from '../../api/user'
import { AppLayout } from '../../components/Layout/AppLayout'
import { GlobalContext } from '../../context/GlobalState'
import { TitleBox } from '../DashboardHistory/DashboardHistory'
import { ColumnsType } from 'antd/lib/table'
import { DebugDataCollection, DebugDataCollectionDocumentItem, Service } from '../../context/types'
import styled from 'styled-components/macro'

const { RangePicker } = DatePicker
const { Option } = Select

const DebugBox = styled.div`
  display: flex;
  max-width: 500px;
  margin: 20px auto;
  flex-direction: column;
  padding: 20px 32px;
  border-radius: 12px;
  background-color: #1d1d1d;
`

const DataExample = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: #319ed1;
  padding-right: 40px;

  &:hover {
    text-decoration: underline;
  }
`

type BaseItem = {
  id: string
  totalCidUnique: number
  totalDocuments: number
}

export const DebugPage = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const { debugCollections, debugStartDate, debugEndDate, debugSid, services, debugData } = state

  const [startDate, setStartDate] = useState<string | null>(debugStartDate)
  const [endDate, setEndDate] = useState<string | null>(debugEndDate)
  const [data, setData] = useState<BaseItem[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCidModalVisible, setCidIsModalVisible] = useState(false)
  const [documents, setDocuments] = useState<DebugDataCollectionDocumentItem[]>([])
  const [selectedCollection, setSelectedCollection] = useState<DebugDataCollection>()
  const [isLoading, setIsLoading] = useState<boolean>()

  const [reload, setReload] = useState(false)

  // useEffect(() => {
  //   async function loadDebug() {
  //     const resp = await debugMe(1, '2021-09-02', '2021-09-03')
  //     console.log(resp.data)
  //   }
  //   loadDebug()
  // }, [])

  useEffect(() => {
    async function loadAllData() {
      try {
        debugMe(debugSid, debugStartDate, debugEndDate).then((resp) => {
          if (resp.status === 200) {
            dispatch(DebugdActions.setDebugData(resp.data))
          }
        })
      } catch (error) {
        console.log(error)
      }
      setReload(false)
    }

    if (reload && debugStartDate !== null && debugEndDate !== null) loadAllData()
  }, [dispatch, debugStartDate, debugEndDate, debugSid, reload])

  const setLocalDates = (val: Moment[]) => {
    setStartDate(val[0].format('yyyy-MM-DD'))
    setEndDate(val[1].format('yyyy-MM-DD'))
  }

  const setGlobalDates = () => {
    if (startDate === null || endDate === null) return
    dispatch(DebugdActions.setFilterDates(startDate, endDate))
    setIsLoading(true)
    setData([])
    setReload(true)
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  useEffect(() => {
    if (debugCollections) {
      const items = []
      for (let key of Array.from(debugCollections.keys())) {
        const item = debugCollections.get(key)
        items.push({ id: key, totalDocuments: item.totalDocuments, totalCidUnique: item.totalCidUnique })
      }
      setData(items)
      setIsLoading(false)
    }
  }, [debugCollections])

  const columns: ColumnsType = [
    {
      title: 'Type',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'totalCidUnique',
      dataIndex: 'totalCidUnique',
      key: 'totalCidUnique'
    },
    {
      title: 'totalDocuments',
      dataIndex: 'totalDocuments',
      key: 'totalDocuments'
    },
    {
      title: 'Action',
      dataIndex: 'data',
      render: (_: string, record: BaseItem) => (
        <span key={record.id}>
          <Button onClick={() => showModal(record.id)}>Dettagli</Button>
        </span>
      )
    }
  ]

  const showModal = (id: string) => {
    const item = debugCollections.get(id)
    const docs = item.Documents
    setDocuments(docs)
    setSelectedCollection(item)
    setIsModalVisible(true)
  }

  const cidData = () => {
    return debugData.totalPremiumLoginEmail.cid.map((c) => {
      return {
        id: c,
        cid: c
      }
    })
  }

  const cidColumns: ColumnsType = [
    {
      title: 'Cid',
      dataIndex: 'Cid',
      key: 'id'
    }
  ]

  const docColumns: ColumnsType = [
    {
      title: 'Cid',
      dataIndex: 'cid',
      key: 'cid'
    },
    {
      title: 'Date',
      dataIndex: 'date_created',
      key: 'date_created'
    }
  ]

  const DataTitle = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h5>Data</h5>
      <div>
        {selectedCollection && selectedCollection.example && (
          <Popover placement="bottomLeft" content={<pre>{JSON.stringify(selectedCollection.example, null, 2)}</pre>} title="Title" trigger="click">
            <DataExample>view example </DataExample>
          </Popover>
        )}
      </div>
    </div>
  )

  return (
    <AppLayout>
      <TitleBox>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>DEBUG</h2>
        </div>

        <div>
          <Space>
            <Select value={debugSid} onChange={(v) => dispatch(DebugdActions.setDebugSid(v))} style={{ width: '200px', marginRight: '20px' }}>
              {services.map((s: Service) => (
                <Option key={s.sid} value={s.sid}>
                  <img src={`/images/${s.country}.png`} alt={s.country} width="16px" height="11px" style={{ opacity: 0.8, marginRight: '4px' }} />
                  {s.name}
                </Option>
              ))}
            </Select>
            <RangePicker
              disabledDate={disabledDate}
              value={[startDate !== null ? moment(startDate) : null, endDate !== null ? moment(endDate) : null]}
              onChange={(val) => setLocalDates(val)}
            />

            <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => setGlobalDates()} />
          </Space>
        </div>
        <div></div>
        {/* <BtnSelectServices panel={<SectionSelector />} styled={false} title="Sections" /> */}
      </TitleBox>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px', color: 'white' }}>
          <Spin size="large" tip="Loading..." />
        </div>
      )}

      {!isLoading && data.length > 0 && (
        <DebugBox>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{debugData.totalPixelUniqueViewFrameFromSignup.description}:</span>
            <span style={{ fontWeight: 'bold' }}>{debugData.totalPixelUniqueViewFrameFromSignup.data}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{debugData.totalRadarRealDistinct.description}:</span>
            <span style={{ fontWeight: 'bold' }}>{debugData.totalRadarRealDistinct.data}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{debugData.totalPremiumLoginEmail.description}:</span>
            <span style={{ fontWeight: 'bold' }} onClick={() => setCidIsModalVisible(true)}>
              {debugData.totalPremiumLoginEmail.data}
            </span>
          </div>
        </DebugBox>
      )}

      <div style={{ maxWidth: '800px', margin: '20px auto' }}>
        <Table dataSource={data} columns={columns} pagination={{ pageSize: 20 }} rowKey="id" />
      </div>
      <Modal
        style={{ minWidth: '900px', width: '40%' }}
        title={<DataTitle />}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        {documents.length > 0 && <Table dataSource={documents} columns={docColumns} rowKey={() => Math.random()} />}
      </Modal>
      <Modal
        style={{ minWidth: '900px', width: '40%' }}
        title="Cid"
        visible={isCidModalVisible}
        onOk={() => setCidIsModalVisible(false)}
        onCancel={() => setCidIsModalVisible(false)}
      >
        {debugData.totalPremiumLoginEmail.cid.length > 0 && <Table dataSource={cidData()} columns={cidColumns} rowKey="id" />}
      </Modal>
    </AppLayout>
  )
}

import { ActionType } from '../context/AppReducer'

export const SET = 'sections/SET'

export const SectionActions = {
  setSections: (sections: string[]): ActionType => {
    return {
      type: 'sections/SET',
      payload: sections
    }
  }
}

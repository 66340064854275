import axios from 'axios'
import { KpiSetting } from '../context/types'

const baseUrl = '/settings'

export const getSettingsKpi = () => axios.get<KpiSetting[]>(`${baseUrl}`)

export const createSettingsKpi = (kpiSetting: KpiSetting) => axios.post<KpiSetting>(`${baseUrl}`, kpiSetting)

export const updateSettingsKpi = (kpiSetting: KpiSetting) => axios.put<KpiSetting>(`${baseUrl}?id=${kpiSetting.id}`, kpiSetting)

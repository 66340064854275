import { ActionType } from '../context/AppReducer'
import { ConversionRate, ConversionRateHistoryResponse } from '../context/types'

export const SET = 'conversionRate/SET'
export const SET_CR_HISTORY = 'conversionRate/SET_CR_HISTORY'
export const SET_CR_SERVIVES = 'conversionRate/SET_CR_SERVICES'

export const ConversionRateActions = {
  setConversionRate: (conversionRates: ConversionRate[]): ActionType => {
    return {
      type: SET,
      payload: conversionRates
    }
  },
  setConversionRateHistory: (dataResponse: ConversionRateHistoryResponse): ActionType => {
    const cr = { ...dataResponse.kpis[0], cat: dataResponse.kpis[0].category }
    const conversionRates = [{ ...cr, sid: dataResponse.sid }]
    return {
      type: SET,
      payload: conversionRates
    }
  },
  setCrServices: (cr: number[]): ActionType => {
    return {
      type: SET_CR_SERVIVES,
      payload: cr
    }
  }
}

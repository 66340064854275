export const LOGIN = '/login'
export const DASHBOARD = '/'
export const DEBUG = '/debug'
export const PIXEL = '/pixel'
export const SERVICE_DETAIL = '/service/:serviceName'
export const SETTINGS = '/settings'
export const SETTINGS_SERVICES = `${SETTINGS}/services`
export const SETTINGS_KPI = `${SETTINGS}/kpi`
export const SEARCH = '/search'
export const SEARCH_USER = `${SEARCH}/infouser`
export const DASHBOARD_HISTORY = `/dashboard-history`

import { Collapse } from 'antd'
import _ from 'lodash'
import { useContext, useEffect } from 'react'
import { DashboradActions } from '../../actions/dashboard'
import { KpiActions } from '../../actions/kpis'
import { getServiceKpisHistory, getServiceKpisHistoryNotAdmin } from '../../api/kpis'
import { GlobalContext } from '../../context/GlobalState'
import { Service } from '../../context/types'
import { ServiceTitle } from '../ServiceTitle/ServiceTitle'
import { KpiRow, KpiRowNotAdmin } from './KpiRow'

const { Panel } = Collapse

// const SingleKpiLoader = ({s}: {s: Service}) => {

//   const { dispatch } = useContext(GlobalContext)
//   const [firstLoaded, setFirstLoaded] = useState(false)

//       const loadData = useCallback(
//         () => {
//           const force = !firstLoaded
//           getServiceKpis(s.sid, force).then((resp) => {
//             if (resp.status === 200) {
//               dispatch(KpiActions.setKpiPanel(resp.data))
//               setFirstLoaded(true)
//             }
//           })
//         },
//         [dispatch, firstLoaded, s.sid],
//       )

//   useEffect(() => {

//     let ti = undefined
//     loadData()
//     if (firstLoaded) {
//       ti = setInterval(loadData, 30000)
//     }

//     return () => {
//        if (ti) clearInterval(ti)
//     }
//   }, [firstLoaded, loadData])
// }

export const KpiContainerHistory = () => {
  const { state, dispatch } = useContext(GlobalContext)
  const { services, serviceKpis, serviceKpisNotAdmin, liveKpiServices, dashboardStartDate, dashboardEndDate } = state

  const isAdmin = state.role === 'admin'
  // const [loadedServices, setLoadedService] = useState<number[]>([])
  // const [isFirstLoad, setIsFirstLoad] = useState(true)

  // const loadData = useCallback(
  //   async (s: Service, force: boolean) => {
  //          getServiceKpis(s.sid, force).then((resp) => {
  //            if (resp.status === 200) {
  //              dispatch(KpiActions.setKpiPanel(resp.data))
  //            }
  //          })

  //   },
  //   [dispatch],
  // )

  useEffect(() => {
    async function loadFirstData() {
      services.forEach(async (s) => {
        if (dashboardStartDate === null || dashboardEndDate === null) return
        dispatch(DashboradActions.setLoadingKpis(s.sid, true))
        if (isAdmin) {
          await getServiceKpisHistory(s.sid, dashboardStartDate, dashboardEndDate).then((resp) => {
            dispatch(DashboradActions.setLoadingKpis(s.sid, false))
            if (resp.status === 200) {
              // setLoadedService((loadedServices) => [...loadedServices, s.sid])
              dispatch(KpiActions.setKpiPanel(resp.data))
            }
          })
        } else {
          await getServiceKpisHistoryNotAdmin(s.sid, dashboardStartDate, dashboardEndDate).then((resp) => {
            dispatch(DashboradActions.setLoadingKpis(s.sid, false))
            if (resp.status === 200) {
              // setLoadedService((loadedServices) => [...loadedServices, s.sid])
              dispatch(KpiActions.setKpiPanelNotAdmin(resp.data))
            }
          })
        }
      })

      // setIsFirstLoad(false)
    }

    loadFirstData()
  }, [dispatch, services, dashboardStartDate, dashboardEndDate, isAdmin])

  // useEffect(() => {
  //   let ti = undefined
  //   async function loadIupayPixelData() {
  //     try {
  //       const resp = await getIupayLastPixel()
  //       dispatch(DashboradActions.setIupayLastPixel(new Date(resp.data)))
  //               const respPing = await getLastPing()
  //               dispatch(DashboradActions.setPingError(respPing.data))
  //     } catch (error) {
  //       //
  //     }
  //   }
  //   ti = setInterval(loadIupayPixelData, 60000)
  //   loadIupayPixelData()
  //   return () => {
  //     if (ti) clearInterval(ti)
  //   }
  // }, [dispatch])

  // useEffect(() => {
  //   let force = true

  //   async function loadAllData() {
  //     services.forEach(async (s) => {
  //       if (!force && !_.includes(loadedServices, s.sid)) {
  //         console.log(s.sid, loadedServices)
  //       } else {
  //         await getServiceKpis(s.sid, force).then((resp) => {
  //           console.log('getting sid ', s.sid, force)
  //           if (resp.status === 200) {
  //             if (force) {
  //               console.log('forced')
  //               setLoadedService([...loadedServices, s.sid])
  //             } else {
  //               console.log('not forced')
  //             }
  //             dispatch(KpiActions.setKpiPanel(resp.data))
  //           }
  //         })
  //       }
  //     })

  //     force = false
  //   }

  //   loadAllData()
  //   let ti = setInterval(loadAllData, 20000)
  //   return () => {
  //     if (ti) clearInterval(ti)
  //   }
  // }, [dispatch, services])

  if (!serviceKpis && !serviceKpisNotAdmin) return null

  const activeServices = services.filter((s: Service) => s.status && _.includes(liveKpiServices, s.sid))

  const KpiListNotAdmin = activeServices.map((s: Service) => {
    const serviceKpi = serviceKpisNotAdmin.get(s.sid)
    if (!serviceKpi) return null
    const panelStyle = {
      backgroundColor: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#721c24' : serviceKpi.status === 'warning' ? '#71721c' : 'transparent',
      color: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#f08790' : 'white',
      borderColor: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#541016' : serviceKpi.status === 'warning' ? '#414210' : '#434343'
    }
    return (
      <Panel
        key={s.sid}
        header={
          <span className={`panel-${serviceKpi.status}`}>
            <ServiceTitle service={s} isLoading={state.isLoadingKpis.get(s.sid)} />
          </span>
        }
        style={panelStyle}
      >
        <KpiRowNotAdmin service={s} serviceKpi={serviceKpi} />
      </Panel>
    )
  })

  const KpiList = activeServices.map((s: Service) => {
    const serviceKpi = serviceKpis.get(s.sid)
    if (!serviceKpi || dashboardStartDate === null) return null

    const panelStyle = {
      backgroundColor: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#721c24' : serviceKpi.status === 'warning' ? '#71721c' : 'transparent',
      color: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#f08790' : 'white',
      borderColor: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#541016' : serviceKpi.status === 'warning' ? '#414210' : '#434343'
    }
    return (
      <Panel
        key={s.sid}
        header={
          <span className={`panel-${serviceKpi.status}`}>
            <ServiceTitle service={s} isLoading={state.isLoadingKpis.get(s.sid)} />
          </span>
        }
        style={panelStyle}
      >
        <KpiRow service={s} serviceKpi={serviceKpi} />
      </Panel>
    )
  })

  return <Collapse defaultActiveKey={[]}>{isAdmin ? KpiList : KpiListNotAdmin}</Collapse>
}


import { Redirect, Route, RouteProps } from 'react-router-dom'
import { LOGIN } from '../../routes'

type Props = {
    component: React.ReactType
    loggedIn: boolean
} & RouteProps

export const AuthRoute = ({ component: Component, loggedIn, ...rest }: Props) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (!loggedIn)
                    return (
                        <Redirect
                            to={{
                                pathname: LOGIN,
                                state: { from: props.location }
                            }}
                        />
                    )

                return <Component {...props} />
            }}
        />
    )
}
import { Box } from "../../components/Layout/AppLayout"


export const Error404Page = () => {

    return (
        <Box>
            404
        </Box>
    )

}
import { ActionType } from '../context/AppReducer'
import { PingError } from '../context/types'

export const SET_RT_HOURS = 'dashboard/SET_RT_HOURS'
export const SET_FILTER_DATES = 'dashboard/SET_FILTER_DATES'
export const SET_IUPAY_LAST_PIXEL = 'dashboard/SET_IUPAY_LAST_PIXEL'
export const SET_LOADING_KPIS = 'dashboard/SET_LOADING_KPIS'
export const SET_KPI_CATEGORIES = 'dashboard/SET_KPI_CATEGORIES'
export const SET_LOADING_CONVERSION_RATES = 'dashboard/SET_LOADING_CONVERSION_RATES'
export const SET_PING_ERROR = 'dashboard/SET_PING_ERROR'

export const DashboradActions = {
  setRtHours: (hours: number): ActionType => {
    return {
      type: SET_RT_HOURS,
      payload: hours
    }
  },
  setFilterDates: (startDate: string, endDate: string): ActionType => {
    return {
      type: SET_FILTER_DATES,
      payload: { startDate, endDate }
    }
  },
  setIupayLastPixel: (pixelDateTime: Date): ActionType => {
    return {
      type: SET_IUPAY_LAST_PIXEL,
      payload: pixelDateTime
    }
  },
  setLoadingKpis: (sid: number, isLoading: boolean): ActionType => {
    return {
      type: SET_LOADING_KPIS,
      payload: { sid, isLoading }
    }
  },
  setLoadingConversionRates: (sid: number, isLoading: boolean): ActionType => {
    return {
      type: SET_LOADING_CONVERSION_RATES,
      payload: { sid, isLoading }
    }
  },
  setKpiCategories: (categories: string[]): ActionType => {
    return {
      type: SET_KPI_CATEGORIES,
      payload: categories
    }
  },
  setPingError: (pingError: PingError): ActionType => {
    return {
      type: SET_PING_ERROR,
      payload: pingError
    }
  }
}

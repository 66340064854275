import { ActionType } from '../context/AppReducer'
import { KpiHistoryNotAdmin, KpiInfo, KpiSetting, ServiceKpis } from '../context/types'

export const SET = 'kpi/SET_SETTINGS'
export const SET_INFO = 'kpi/SET_INFO'
export const SET_SERVICE_KPIS = 'kpi/SET_SERVICE_KPIS'
export const SET_SERVICE_KPIS_NOT_ADMIN = 'kpi/SET_SERVICE_KPIS_NOT_ADMIN'
export const SET_SEARCH_KPI = 'kpi/SET_SEARCH_KPI'
export const SET_FILTER_KPI = 'kpi/SET_FILTER_KPI'

export type SearchKpiPayload = {
  word: string
  type: 'filter' | 'highlight'
}

export const KpiActions = {
  setKpiSettings: (kpiSettings: KpiSetting[]): ActionType => {
    return {
      type: SET,
      payload: kpiSettings
    }
  },
  setKpiInfo: (kpiInfo: KpiInfo): ActionType => {
    return {
      type: SET_INFO,
      payload: kpiInfo
    }
  },
  setKpiPanel: (serviceKpis: ServiceKpis): ActionType => {
    return {
      type: SET_SERVICE_KPIS,
      payload: serviceKpis
    }
  },
  setKpiPanelNotAdmin: (serviceKpis: KpiHistoryNotAdmin): ActionType => {
    return {
      type: SET_SERVICE_KPIS_NOT_ADMIN,
      payload: serviceKpis
    }
  },
  setKpiSearch: (searchKpiPayload: SearchKpiPayload): ActionType => {
    return {
      type: SET_SEARCH_KPI,
      payload: searchKpiPayload
    }
  },
  setFilterKpi: (filteredKpis: number[]): ActionType => {
    return {
      type: SET_FILTER_KPI,
      payload: filteredKpis
    }
  }
}

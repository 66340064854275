import jwt_decode from 'jwt-decode'
import { getAccessToken } from '../utils/token'
import { ChatResponse, ConversionRate, KpiHistoryNotAdmin, RootState, ServiceKpis } from './types'

export type ActionType = {
  type: // | 'LOAD_CONFIG'
  | 'session/SET'
    | 'session/RESTORE'
    | 'session/CLOSE'
    | 'socket/SET'
    | 'services/SET'
    | 'kpi/SET_SETTINGS'
    | 'kpi/SET_INFO'
    | 'country/SET'
    | 'kpi/SET_SERVICE_KPIS'
    | 'kpi/SET_SERVICE_KPIS_NOT_ADMIN'
    | 'conversionRate/SET'
    | 'conversionRate/SET_CR_SERVICES'
    | 'socket/SET_LIVECHAT_SERVICES'
    | 'kpi/SET_SEARCH_KPI'
    | 'kpi/SET_FILTER_KPI'
    | 'services/SET_LIVEKPI_SERVICES'
    | 'sections/SET'
    | 'dashboard/SET_RT_HOURS'
    | 'dashboard/SET_KPI_CATEGORIES'
    | 'dashboard/SET_LOADING_KPIS'
    | 'dashboard/SET_LOADING_CONVERSION_RATES'
    | 'dashboard/SET_IUPAY_LAST_PIXEL'
    | 'dashboard/SET_FILTER_DATES'
    | 'dashboard/SET_PING_ERROR'
    | 'pixel/SET_PIXELS'
    | 'pixel/SET_START_DATE'
    | 'pixel/SET_SID'
    | 'debug/SET_FILTER_DATES'
    | 'debug/SET_SID'
    | 'debug/SET_DATA'
  payload?: any
}

export const reducer = (state: RootState, action: ActionType): RootState => {
  switch (action.type) {
    // case 'LOAD_CONFIG': {
    //     return {
    //         ...state,
    //         isFetching: true,
    //         error: false
    //     }
    // }
    case 'session/SET': {
      const { token } = action.payload
      localStorage.setItem('kpi_token', token)

      const { data }: any = jwt_decode(token)

      return {
        ...state,
        isLogged: true,
        token,
        username: data.username,
        role: data.roles
      }
    }
    case 'session/RESTORE': {
      const token = getAccessToken()
      const { data }: any = jwt_decode(token)

      return {
        ...state,
        isLogged: true,
        token,
        username: data.username,
        role: data.roles
      }
    }

    case 'session/CLOSE': {
      localStorage.removeItem('kpi_token')

      return {
        ...state,
        token: undefined,
        isLogged: false,
        username: '',
        role: 'mkt'
      }
    }

    case 'socket/SET': {
      const chatResponse: ChatResponse = action.payload
      if (!chatResponse) return { ...state }
      const newSockets = new Map(state.sockets)
      console.log({ chatResponse })
      newSockets.set(chatResponse.sid, chatResponse)
      return {
        ...state,
        sockets: newSockets
      }
    }
    case 'services/SET': {
      return {
        ...state,
        services: action.payload
      }
    }

    case 'sections/SET': {
      localStorage.setItem('sections', JSON.stringify(action.payload))
      return {
        ...state,
        sections: action.payload
      }
    }

    case 'kpi/SET_SETTINGS': {
      return {
        ...state,
        kpiSettings: action.payload
      }
    }
    case 'kpi/SET_INFO': {
      return {
        ...state,
        kpiInfo: { ...action.payload, kpi: [...action.payload.kpi] }
      }
    }
    case 'country/SET': {
      return {
        ...state,
        countries: action.payload
      }
    }
    case 'conversionRate/SET': {
      const conversionRate: ConversionRate[] = action.payload
      console.log(conversionRate[0])
      const newConversionRates = new Map(state.conversionRates)
      newConversionRates.set(conversionRate[0].sid, conversionRate)
      return {
        ...state,
        conversionRates: newConversionRates
      }
    }
    case 'conversionRate/SET_CR_SERVICES': {
      const crService: number[] = action.payload
      localStorage.setItem('crServices', JSON.stringify(crService))

      return {
        ...state,
        crServices: [...crService]
      }
    }
    case 'socket/SET_LIVECHAT_SERVICES': {
      const liveChatServices: number[] = action.payload
      localStorage.setItem('lcServices', JSON.stringify(liveChatServices))

      return {
        ...state,
        liveChatServices: [...liveChatServices]
      }
    }
    case 'services/SET_LIVEKPI_SERVICES': {
      const liveKpiServices: number[] = action.payload
      localStorage.setItem('kpiServices', JSON.stringify(liveKpiServices))

      return {
        ...state,
        liveKpiServices: [...liveKpiServices]
      }
    }

    case 'kpi/SET_SERVICE_KPIS_NOT_ADMIN': {
      const serviceKpis: KpiHistoryNotAdmin = action.payload
      const newserviceKpis = new Map(state.serviceKpisNotAdmin)
      newserviceKpis.set(serviceKpis.sid, serviceKpis)
      return {
        ...state,
        serviceKpisNotAdmin: newserviceKpis
      }
    }

    case 'kpi/SET_SERVICE_KPIS': {
      const serviceKpis: ServiceKpis = action.payload
      const newserviceKpis = new Map(state.serviceKpis)
      newserviceKpis.set(serviceKpis.sid, serviceKpis)
      return {
        ...state,
        serviceKpis: newserviceKpis
      }
    }

    case 'kpi/SET_SEARCH_KPI': {
      return {
        ...state,
        searchKpi: action.payload.word,
        filterOrHighlightKpi: action.payload.type
      }
    }

    case 'kpi/SET_FILTER_KPI': {
      return {
        ...state,
        filteredKpis: action.payload
      }
    }
    case 'dashboard/SET_RT_HOURS': {
      return {
        ...state,
        rt_hours: action.payload
      }
    }
    case 'dashboard/SET_KPI_CATEGORIES': {
      localStorage.setItem('kpi_categories', JSON.stringify(action.payload))
      return {
        ...state,
        slectedCategories: action.payload
      }
    }
    case 'dashboard/SET_PING_ERROR': {
      return {
        ...state,
        pingError: action.payload
      }
    }
    // case 'dashboard/SET_LOADING_KPIS': {
    //   console.log('Loading: ', action.payload)
    //   return {
    //     ...state,
    //     isLoadingKpis: action.payload
    //   }
    // }
    case 'dashboard/SET_LOADING_KPIS': {
      // console.log(action)
      const sid: number = action.payload.sid
      const isLoading = action.payload.isLoading
      const newIsLoadingKpis = new Map(state.isLoadingKpis)
      newIsLoadingKpis.set(sid, isLoading)
      return {
        ...state,
        isLoadingKpis: newIsLoadingKpis
      }
    }

    case 'dashboard/SET_LOADING_CONVERSION_RATES': {
      // console.log(action)
      const sid: number = action.payload.sid
      const isLoading = action.payload.isLoading
      const newIsLoadingConversionRates = new Map(state.isLoadingConversionRates)
      newIsLoadingConversionRates.set(sid, isLoading)
      return {
        ...state,
        isLoadingConversionRates: newIsLoadingConversionRates
      }
    }

    case 'dashboard/SET_IUPAY_LAST_PIXEL': {
      return {
        ...state,
        iupayLastPixel: action.payload
      }
    }

    case 'dashboard/SET_FILTER_DATES': {
      const { startDate, endDate } = action.payload
      return {
        ...state,
        dashboardStartDate: startDate,
        dashboardEndDate: endDate
      }
    }

    case 'debug/SET_FILTER_DATES': {
      const { startDate, endDate } = action.payload
      return {
        ...state,
        debugStartDate: startDate,
        debugEndDate: endDate
      }
    }

    case 'pixel/SET_PIXELS': {
      return {
        ...state,
        pixels: action.payload
      }
    }

    case 'pixel/SET_START_DATE': {
      return {
        ...state,
        pixelStartDate: action.payload
      }
    }

    case 'pixel/SET_SID': {
      return {
        ...state,
        pixelSid: action.payload
      }
    }

    case 'debug/SET_SID': {
      return {
        ...state,
        debugSid: action.payload
      }
    }

    case 'debug/SET_DATA': {
      const newCollections = new Map(state.debugCollections)
      const { collections, ...rest } = action.payload
      for (let col of Object.keys(collections)) {
        newCollections.set(col, collections[col])
      }

      return {
        ...state,
        debugData: { ...rest },
        debugCollections: newCollections
      }
    }

    default: {
      return state
    }
  }
}

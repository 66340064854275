import axios from 'axios'
import { DebugData } from '../context/types'

export type LoginTokenResponse = {
  token: string
  refreshToken?: string
}

export const loginUser = (username: string, password: string) => axios.post<LoginTokenResponse>('/login', { username, password })

export const me = () => axios.get<LoginTokenResponse>(`/me`)

export const debugMe = (sid: number, startDate: string, endDate: string) => axios.get<DebugData>(`/debug?sid=${sid}&date_start=${startDate}&date_end=${endDate}&data_storage=mongo`)

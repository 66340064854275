import _ from 'lodash'
import { useContext, useEffect, useMemo } from 'react'
import { Col, Row, Tooltip } from 'antd'
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'

import { ConversionRateActions } from '../../actions/conersionRates'
import { getConversionRates } from '../../api/conversionRates'
import { GlobalContext } from '../../context/GlobalState'
import { Service } from '../../context/types'
import { ChatCard } from '../ChatSocket/style'
import { DashboradActions } from '../../actions/dashboard'

const loadingSpin = <LoadingOutlined style={{ fontSize: 10 }} spin />

export const ConversionRate = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const { services, conversionRates, crServices, rt_hours } = state

  const activeServices = useMemo(() => {
    return services.filter((s: Service) => s.status && _.includes(crServices, s.sid))
  }, [crServices, services])

  // useEffect(() => {
  //   console.log(activeServices)
  // }, [activeServices])

  useEffect(() => {
    let force = true
    async function loadAllData() {
      activeServices.forEach((s) => {
        try {
          dispatch(DashboradActions.setLoadingConversionRates(s.sid, true))
          getConversionRates(s.sid, force, rt_hours).then((resp) => {
            if (resp.status === 200) {
              dispatch(ConversionRateActions.setConversionRate(resp.data))
            }
            dispatch(DashboradActions.setLoadingConversionRates(s.sid, false))
          })
        } catch (error) {
          console.log(error)
        }
      })

      force = false
    }
    let ti = undefined

    if (activeServices.length > 0) {
      loadAllData()
      ti = setInterval(loadAllData, 20000)
    }

    return () => {
      if (ti) clearInterval(ti)
    }
  }, [dispatch, activeServices, rt_hours])

  if (conversionRates.size === 0) return null

  const popUp = (info: { [key: string]: any }) => {
    const arr = []
    Object.keys(info).forEach((k) => {
      if (k !== 'Conversion Rate RM') {
        if (k === 'Red Signup') {
          arr.push(
            <hr
              key={k}
              style={{ border: 0, height: 1, backgroundImage: 'linear-gradient(to right, rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.75), rgba(128, 128, 128, 0.2))' }}
            />
          )
          const ob = info[k]
          Object.keys(ob).forEach((s) => {
            arr.push(
              <div key={s} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ color: '#888', paddingLeft: '8px' }}>{s}</div>
                <div style={{ fontWeight: 'bold', paddingRight: '8px' }}>{ob[s]}</div>
              </div>
            )
          })
        } else {
          arr.push(
            <div key={k} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ color: '#888', paddingLeft: '8px' }}>{k}</div>
              <div style={{ fontWeight: 'bold', paddingRight: '8px' }}>{info[k]}</div>
            </div>
          )
        }
      }
    })

    return <div style={{ width: '220px' }}>{arr}</div>
  }

  const displayData = activeServices.map((s: Service) => {
    const services = conversionRates.get(s.sid)

    if (!services || services.length === 0) return null

    return services.map((service) => {
      const titleBox = (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src={`/images/${s.country}.png`} alt={s.country} width="16px" height="11px" style={{ opacity: 0.8, marginRight: '4px' }} />
            <span className="truncate">
              {s.name} [{service.cat}]
            </span>
            <Tooltip title={service && service.status !== 'unavailable' ? popUp(service.info) : <span>No data</span>}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <h2>
            {service.status !== 'unavailable' ? (
              <>
                {service.title.split(' ')[0]}
                <span className="small-label">{service.title.split(' ')[1]}</span>
              </>
            ) : (
              'n/d'
            )}
          </h2>
          {service && service.status !== 'unavailable' && (
            <h5>
              <div className="sublabel">RM</div>
              {service.info['Conversion Rate RM']} %
            </h5>
          )}
          <div style={{ position: 'absolute', right: -2 }}>{state.isLoadingKpis.get(s.sid) ? loadingSpin : null}</div>
        </div>
      )

      return (
        <Col xs={12} sm={12} md={12} lg={8} xl={4} xxl={2} key={`${s.sid}-${service.cat}-item`}>
          <ChatCard $alert={false} $status={service ? service.status : 'loading'} $loading={!service || service.status === 'unavailable'} title={titleBox}></ChatCard>
        </Col>
      )
    })
  })

  return <Row gutter={8}>{displayData}</Row>
}

import _ from 'lodash'
import { useContext } from 'react'
import { DashboradActions } from '../../actions/dashboard'
import { GlobalContext } from '../../context/GlobalState'

import { ReactComponent as Mobile } from '../../assets/svgs/app.svg'
import { ReactComponent as Web } from '../../assets/svgs/web.svg'
import { ReactComponent as Splash2 } from '../../assets/svgs/promotion.svg'
import { Tooltip } from 'antd'

const KPI_CATEGORIES = ['app', 'web', 'splash2']

export const SelectCategory = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const { slectedCategories } = state

  // useEffect(() => {
  //   dispatch(DashboradActions.setKpiCategories(KPI_CATEGORIES))
  // }, [dispatch])

  const toggleCategory = (cat: string) => {
    if (_.includes(slectedCategories, cat)) {
      const newCategories = slectedCategories.filter((c: string) => c !== cat)
      dispatch(DashboradActions.setKpiCategories(newCategories))
    } else {
      dispatch(DashboradActions.setKpiCategories([...slectedCategories, cat]))
    }
  }

  const CategoryIcons = KPI_CATEGORIES.map((cat: string) => {
    let color = '#555'
    if (_.includes(slectedCategories, cat)) {
      color = 'rgb(23, 125, 220)'
    }

    if (cat === 'web')
      return (
        <Tooltip title={cat} key={cat}>
          <Web fill={color} onClick={() => toggleCategory(cat)} />
        </Tooltip>
      )
    if (cat === 'splash2')
      return (
        <Tooltip title={cat} key={cat}>
          <Splash2 fill={color} onClick={() => toggleCategory(cat)} />{' '}
        </Tooltip>
      )
    if (cat === 'app')
      return (
        <Tooltip title={cat} key={cat}>
          <Mobile fill={color} onClick={() => toggleCategory(cat)} />{' '}
        </Tooltip>
      )
    return null
  })

  return (
    <div className="category-select-wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px', paddingTop: '4px', alignItems: 'center' }}>{CategoryIcons}</div>
    </div>
  )
}

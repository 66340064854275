import { Redirect, Route, RouteProps } from 'react-router-dom'

type Props = {
    loggedIn: boolean
    redirect: string
} & RouteProps

export const UnauthRoute = ({ loggedIn, redirect, ...rest }: Props) => {
    return loggedIn ? <Redirect to={redirect} /> : <Route {...rest} />
}

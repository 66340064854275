import { ActionType } from '../context/AppReducer'
import { Service } from '../context/types'

export const SET = 'services/SET'
export const SET_LIVEKPI_SERVICES = 'services/SET_LIVEKPI_SERVICES'

export const ServicesActions = {
  setServices: (services: Service[]): ActionType => {
    // const newServices = services.map((s: Service) => {
    //   return {
    //     ...s,
    //     name: `${s.name} [${s.country}]`
    //   }
    // })
    return {
      type: SET,
      payload: services
    }
  },
  setLiveKpiServices: (kpis: number[]): ActionType => {
    return {
      type: SET_LIVEKPI_SERVICES,
      payload: kpis
    }
  }
}

import { Modal, notification } from 'antd'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ConversionRateActions } from './actions/conersionRates'
import { CountryActions } from './actions/countries'
import { DashboradActions } from './actions/dashboard'
import { KpiActions } from './actions/kpis'
import { SectionActions } from './actions/sections'
import { ServicesActions } from './actions/services'
import { SessionActions } from './actions/session'
import { SocketActions } from './actions/socket'
import { applyAxiosInterceptors } from './api/axios'
import { getCountries } from './api/country'
import { getIupayLastPixel, getKpiInfo, getLastPing } from './api/kpis'
import { getServices } from './api/services'
import { getSettingsKpi } from './api/settings'
import { me } from './api/user'
import './App.css'
import { SuspenceFallback } from './components/Layout/SuspenceFallback'
import { AuthRoute } from './components/Routes/AuthRoute'
import { UnauthRoute } from './components/Routes/UnauthRoute'
import { GlobalContext, GlobalProvider } from './context/GlobalState'
import { PingErrorLog, Service } from './context/types'
import { DashboardPage } from './pages/Dashboard'
import { DashboardHistoryPage } from './pages/DashboardHistory/DashboardHistory'
import { DebugPage } from './pages/Debug/DebugPage'
import { Error404Page } from './pages/ErrorPages/Error404Page'
import { LoginPage } from './pages/Login'
import PixelPage from './pages/Pixel'
import { SearchInfoUser } from './pages/Search/InfoUser'
import { ServicePage } from './pages/Service/Service'
import { SettingsKPIPage } from './pages/Settings/SettingsKPIPage'
import { SettingsServicesPage } from './pages/Settings/SettingsServicesPage'
import * as route from './routes'
import { setAccessToken } from './utils/token'

export const BASE_SECTIONS = ['Real Time Conversion Rate', 'Live Chat', 'Real Time KPI overview']

const Root = () => {
  const { state, dispatch } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const { isLogged } = state

  applyAxiosInterceptors(dispatch)

  function countDown(msg: ReactNode) {
    let secondsToGo = 30
    const modal = Modal.error({
      title: 'ERROR PING',
      content: msg,
      className: 'pingerror'
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
    }, secondsToGo * 1000)
  }

  useEffect(() => {
    async function checkToken() {
      const getToken = localStorage.getItem('kpi_token')
      if (getToken) {
        setAccessToken(getToken)
        await me()
        dispatch(SessionActions.restoreSession())
      }

      setLoading(false)
    }
    checkToken()
  }, [dispatch])

  useEffect(() => {
    let ti = undefined
    async function loadMenu() {
      try {
        await getServices().then((resp) => dispatch(ServicesActions.setServices(resp.data)))

        getCountries().then((resp) => dispatch(CountryActions.setCountries(resp.data)))
        await getKpiInfo().then((resp) => dispatch(KpiActions.setKpiInfo(resp.data)))
        await getSettingsKpi().then((resp) => dispatch(KpiActions.setKpiSettings(resp.data)))
      } catch (error) {
        notification.error({
          message: 'Server Error'
        })
      }
    }
    async function loadPingErrorAndIupay() {
      await getIupayLastPixel().then((resp) => dispatch(DashboradActions.setIupayLastPixel(new Date(resp.data))))
      await getLastPing().then((resp2) => dispatch(DashboradActions.setPingError(resp2.data)))
    }
    if (isLogged) {
      loadMenu()
      ti = setInterval(loadPingErrorAndIupay, 60000)
      loadPingErrorAndIupay()
    }

    return () => {
      if (ti) clearInterval(ti)
    }
  }, [dispatch, isLogged])

  useEffect(() => {
    if (state.pingError.live && state.pingError.live.length > 0) {
      const msg = state.pingError.live.map((c: PingErrorLog) => (
        <div key={`err-${c.id}`}>
          {c.content}
          <div className="subpar">{c.date_created}</div>
        </div>
      ))
      countDown(msg)
    }
  }, [state.pingError])

  useEffect(() => {
    async function getActiveServices() {
      const getCrServices = localStorage.getItem('crServices')
      const activeServices = state.services.filter((s: Service) => s.status).map((s: Service) => s.sid)
      if (!!getCrServices) {
        const objCrServices = JSON.parse(getCrServices)
        dispatch(ConversionRateActions.setCrServices(objCrServices))
      } else {
        dispatch(ConversionRateActions.setCrServices(activeServices))
      }
      const getLivechatServices = localStorage.getItem('lcServices')
      if (!!getLivechatServices) {
        const objLivechatServices = JSON.parse(getLivechatServices)
        dispatch(SocketActions.setLiveChatServices(objLivechatServices))
      } else {
        dispatch(SocketActions.setLiveChatServices(activeServices))
      }
      const getLiveKpiServices = localStorage.getItem('kpiServices')
      if (!!getLiveKpiServices) {
        const objLiveKpiServices = JSON.parse(getLiveKpiServices)
        dispatch(ServicesActions.setLiveKpiServices(objLiveKpiServices))
      } else {
        dispatch(ServicesActions.setLiveKpiServices(activeServices))
      }

      const getSections = localStorage.getItem('sections')
      if (!!getSections) {
        const objSections = JSON.parse(getSections)
        dispatch(SectionActions.setSections(objSections))
      } else {
        dispatch(SectionActions.setSections(BASE_SECTIONS))
      }

      const getKpiCategories = localStorage.getItem('kpi_categories')
      if (!!getKpiCategories) {
        const objKpiCategories = JSON.parse(getKpiCategories)
        dispatch(DashboradActions.setKpiCategories(objKpiCategories))
      } else {
        dispatch(DashboradActions.setKpiCategories(['app', 'web', 'splash2']))
      }
    }

    if (state.services && state.services.length > 0) {
      getActiveServices()
    }
  }, [state.services, dispatch])

  if (loading) return null

  return (
    <SuspenceFallback>
      <Switch>
        <AuthRoute loggedIn={isLogged} path={route.DASHBOARD} exact component={DashboardPage} />
        <AuthRoute loggedIn={isLogged} path={route.PIXEL} exact component={PixelPage} />
        <AuthRoute loggedIn={isLogged} path={route.DEBUG} exact component={DebugPage} />
        <AuthRoute loggedIn={isLogged} path={route.SERVICE_DETAIL} exact component={ServicePage} />
        <AuthRoute loggedIn={isLogged} path={route.SETTINGS_SERVICES} exact component={SettingsServicesPage} />
        <AuthRoute loggedIn={isLogged} path={route.SETTINGS_KPI} exact component={SettingsKPIPage} />
        <AuthRoute loggedIn={isLogged} path={route.SEARCH_USER} exact component={SearchInfoUser} />
        <AuthRoute loggedIn={isLogged} path={route.DASHBOARD_HISTORY} exact component={DashboardHistoryPage} />
        <UnauthRoute loggedIn={isLogged} path={route.LOGIN} redirect={route.DASHBOARD} component={LoginPage} />
        <Route>
          <Error404Page />
        </Route>
      </Switch>
    </SuspenceFallback>
  )
}

function App() {
  return (
    <GlobalProvider>
      <Router>
        <Root />
      </Router>
    </GlobalProvider>
  )
}

export default App

import React, { useContext } from 'react'
import { Row } from 'antd'
import { KpiHistoryNotAdmin, KpiItem, Service, ServiceKpis } from '../../context/types'
import KpiBoxItem from './KpiIBoxtem'
import { GlobalContext } from '../../context/GlobalState'
import _ from 'lodash'
import styled from 'styled-components'
import { mapCategory } from '../SearchKpi/SearchKpi'
interface KpiRowProps {
  service: Service
  serviceKpi: ServiceKpis
}
interface KpiRowNotAdminProps {
  service: Service
  serviceKpi: KpiHistoryNotAdmin
}

const colorMapBg = {
  limits_warning: '#f7d14ab8',
  limits_danger: '#fb3d3d5c',
  none: 'transparent'
}

const colorMapFg = {
  limits_warning: 'white',
  limits_danger: 'white',
  none: '#888'
}

const InfoLabel = styled.div<{ $key: string }>`
  background-color: ${({ $key }) => `${colorMapBg[$key]}`};
  color: ${({ $key }) => `${colorMapFg[$key]}`};
`

const popUp = (info: { [key: string]: any }) => {
  const arr = []
  Object.keys(info).forEach((k) => {
    const key = _.includes(['limits_danger', 'limits_warning'], k) ? k : 'none'
    if (k === 'x' && Number(info[k]) === 0) {
      //
    } else {
      arr.push(
        <InfoLabel $key={key} key={k} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ paddingLeft: '8px' }}>{k}</div>
          <div style={{ fontWeight: 'bold', paddingRight: '8px' }}>{info[k]}</div>
        </InfoLabel>
      )
    }
  })
  return <div style={{ width: '220px' }}>{arr}</div>
}

export const KpiRow = ({ service, serviceKpi }: KpiRowProps) => {
  const { state } = useContext(GlobalContext)
  // console.log(serviceKpi.kpis)
  return (
    <Row gutter={8}>
      {_.orderBy(serviceKpi.kpis, (o) => o.info['interest']).map((k: KpiItem) => {
        const popup = popUp(k.info)
        const interest = k.info['interest'] || ''
        const _id = typeof k.id === 'number' ? k.id : +`${k.id}`.split('_')[1]

        if (state.filteredKpis.length > 0 && !_.includes(state.filteredKpis, _id)) return null
        if (!_.includes(state.slectedCategories, k.category.toLocaleLowerCase())) return null

        return (
          <KpiBoxItem
            interest={interest}
            key={`kpi-item-${service.sid}-${k.id}`}
            popup={popup}
            title={k.title as string}
            body={k.body}
            alert={k.alert}
            type={k.type}
            category={k.category}
          />
        )
      })}
    </Row>
  )
}

export const KpiRowNotAdmin = ({ service, serviceKpi }: KpiRowNotAdminProps) => {
  const { state } = useContext(GlobalContext)
  const allKpiSelected = Object.keys(serviceKpi.kpis)
  console.log(allKpiSelected)

  return (
    <Row gutter={8}>
      {_.orderBy(state.kpiSettings, (o) => o.interest).map((k) => {
        const interest = k['interest'] || ''
        const category = mapCategory[k.cat_id]
        const media = serviceKpi.kpis[k.id]

        if (state.filteredKpis.length > 0 && !_.includes(state.filteredKpis, k.id)) return null
        if (!_.includes(state.slectedCategories, category.toLocaleLowerCase())) return null
        if (!_.includes(allKpiSelected, k.id.toString())) return null
        return (
          <KpiBoxItem
            interest={interest}
            key={`kpi-item-${service.sid}-${k.id}`}
            popup={null}
            title={`${media} ${k.unit}`}
            body={k.description}
            alert={serviceKpi.status}
            type={k.type}
            category={category}
          />
        )
      })}
    </Row>
  )
}

import { Card } from 'antd'
import styled from 'styled-components/macro'

export const ChatCard = styled(Card)<{ $alert: boolean; $status: string; $loading: boolean }>`
  margin-bottom: 10px;
  .ant-card-head {
    padding: 0 8px;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-card-head-title {
    text-align: center;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 8px;
    font-size: 12px;
  }

  h2 {
    background: ${({ $status }) => ($status === 'loading' ? '#555' : $status === 'danger' ? '#d03737' : $status === 'warning' ? 'yellow' : 'rgb(72,180,97)')};
    background: ${({ $status }) =>
      $status === 'loading'
        ? '#555'
        : $status === 'danger'
        ? 'linear-gradient(187deg, rgba(255,0,0,1) 0%, rgba(167,40,0,1) 100%)'
        : $status === 'warning'
        ? 'linear-gradient(187deg, #adb448 0%, #a7a528 100%)'
        : 'linear-gradient(187deg, rgba(72,180,97,1) 0%, rgba(40,167,69,1) 100%)'};
    margin: 10px 12px 2px;
    padding: 2px 0 0 0;
    white-space: nowrap;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2em;
    min-height: 32px;
  }

  h5 {
    background: ${({ $alert, $loading }) => ($loading ? '#555' : $alert ? '#d03737' : 'rgb(72,180,97)')};
    background: ${({ $alert, $loading }) =>
      $loading ? '#555' : $alert ? 'linear-gradient(187deg, rgba(255,0,0,1) 0%, rgba(167,40,0,1) 100%)' : 'linear-gradient(187deg,rgb(21 113 43) 0%,rgb(31 97 45) 100%)'};
    margin: 0px 12px;
    padding: 8px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    line-height: 1em;
    position: relative;

    .sublabel {
      position: absolute;
      bottom: 2px;
      left: 6px;
      font-size: 10px;
    }
  }
`

export const InfoBox = styled.div`
  .body-service-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .body-service-info-label {
      color: #888;
    }
    .body-service-info-value {
      font-weight: 500;
    }
  }

  .body-service-info-boxed {
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
    padding-bottom: 4px;
    margin-bottom: 4px;
  }
`

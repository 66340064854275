import { ActionType } from '../context/AppReducer'
import { Country } from '../context/types'

export const SET = 'country/SET'

export const CountryActions = {
  setCountries: (countries: Country[]): ActionType => {
    return {
      type: SET,
      payload: countries
    }
  }
}

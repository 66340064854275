import { Collapse } from 'antd'
import _ from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { DashboradActions } from '../../actions/dashboard'
import { KpiActions } from '../../actions/kpis'
import { getServiceKpis } from '../../api/kpis'
import { GlobalContext } from '../../context/GlobalState'
import { Service } from '../../context/types'
import { ServiceTitle } from '../ServiceTitle/ServiceTitle'
import { KpiRow } from './KpiRow'

const { Panel } = Collapse

// const SingleKpiLoader = ({s}: {s: Service}) => {

//   const { dispatch } = useContext(GlobalContext)
//   const [firstLoaded, setFirstLoaded] = useState(false)

//       const loadData = useCallback(
//         () => {
//           const force = !firstLoaded
//           getServiceKpis(s.sid, force).then((resp) => {
//             if (resp.status === 200) {
//               dispatch(KpiActions.setKpiPanel(resp.data))
//               setFirstLoaded(true)
//             }
//           })
//         },
//         [dispatch, firstLoaded, s.sid],
//       )

//   useEffect(() => {

//     let ti = undefined
//     loadData()
//     if (firstLoaded) {
//       ti = setInterval(loadData, 30000)
//     }

//     return () => {
//        if (ti) clearInterval(ti)
//     }
//   }, [firstLoaded, loadData])
// }

export const KpiContainer = () => {
  const { state, dispatch } = useContext(GlobalContext)
  const { services, serviceKpis, liveKpiServices, rt_hours } = state
  const [loadedServices, setLoadedService] = useState<number[]>([])
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const activeServices = useMemo(() => {
    return services.filter((s: Service) => s.status && _.includes(liveKpiServices, s.sid))
  }, [liveKpiServices, services])

  // const loadData = useCallback(
  //   async (s: Service, force: boolean) => {
  //          getServiceKpis(s.sid, force).then((resp) => {
  //            if (resp.status === 200) {
  //              dispatch(KpiActions.setKpiPanel(resp.data))
  //            }
  //          })

  //   },
  //   [dispatch],
  // )

  useEffect(() => {
    async function loadFirstData() {
      activeServices.forEach(async (s) => {
        dispatch(DashboradActions.setLoadingKpis(s.sid, true))
        await getServiceKpis(s.sid, true, rt_hours).then((resp) => {
          dispatch(DashboradActions.setLoadingKpis(s.sid, false))
          if (resp.status === 200) {
            setLoadedService((loadedServices) => [...loadedServices, s.sid])
            dispatch(KpiActions.setKpiPanel(resp.data))
          }
        })
      })

      setIsFirstLoad(false)
    }

    loadFirstData()
  }, [dispatch, activeServices, rt_hours])

  useEffect(() => {
    async function loadAllData() {
      activeServices.forEach(async (s) => {
        if (_.includes(loadedServices, s.sid)) {
          dispatch(DashboradActions.setLoadingKpis(s.sid, true))
          await getServiceKpis(s.sid, false, rt_hours).then((resp) => {
            dispatch(DashboradActions.setLoadingKpis(s.sid, false))
            if (resp.status === 200) {
              setLoadedService((loadedServices) => [...loadedServices, s.sid])
              dispatch(KpiActions.setKpiPanel(resp.data))
            }
          })
        }
      })
    }

    let ti = undefined

    if (activeServices.length > 0 && isFirstLoad === false) {
      console.log('ok')
      loadAllData()
      ti = setInterval(loadAllData, 60000)
    }

    return () => {
      if (ti) clearInterval(ti)
    }
  }, [dispatch, isFirstLoad, activeServices, loadedServices, rt_hours, activeServices.length])

  if (!serviceKpis) return null

  const KpiList = activeServices.map((s: Service) => {
    const serviceKpi = serviceKpis.get(s.sid)
    if (!serviceKpi) return null

    const panelStyle = {
      backgroundColor: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#721c24' : serviceKpi.status === 'warning' ? '#71721c' : 'transparent',
      color: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#f08790' : 'white',
      borderColor: serviceKpi.status === 'error' || serviceKpi.status === 'danger' ? '#541016' : serviceKpi.status === 'warning' ? '#414210' : '#434343'
    }
    return (
      <Panel
        key={s.sid}
        header={
          <span className={`panel-${serviceKpi.status}`}>
            <ServiceTitle service={s} isLoading={state.isLoadingKpis.get(s.sid)} />
          </span>
        }
        style={panelStyle}
      >
        <KpiRow service={s} serviceKpi={serviceKpi} />
      </Panel>
    )
  })

  return <Collapse defaultActiveKey={[]}>{KpiList}</Collapse>
}

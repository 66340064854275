import { useContext, useState } from 'react'
import { Form, Input, Button, Select, Divider, Table, Row, Col, Empty, Spin, PageHeader, Timeline, Space, Tooltip } from 'antd'

import { AppLayout } from '../../components/Layout/AppLayout'
import ClockCircleOutlined from '@ant-design/icons/lib/icons/ClockCircleOutlined'
import { KpiTitle } from '../../components/Text/Title'
import { GlobalContext } from '../../context/GlobalState'
import { CidEncounter, CidMessage, CidMessageSplash2, CidOpenFrame, CidPixelBase, CidResponse, CidSignup, CidVisitSplash2, Service } from '../../context/types'
import { getUserInfo } from '../../api/cid'
import { format } from 'date-fns'
import { ColumnsType } from 'antd/lib/table'
import { writeSeconds } from '../../utils/write-seconds'
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined'
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined'

const { Option } = Select

export const SearchInfoUser = () => {
  const { state } = useContext(GlobalContext)
  const { services } = state

  const [isLoading, setIsLoading] = useState<boolean>()
  const [userInfo, setUserInfo] = useState<CidResponse>()

  const [form] = Form.useForm()

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 4 }
  }

  const onFinish = async (values: { cid: string; sid: number }) => {
    setIsLoading(true)
    const sidToSend = values.sid === null ? undefined : values.sid
    const resp = await getUserInfo(sidToSend, values.cid)
    setUserInfo(resp.data)
    // console.log(resp)
    setIsLoading(false)
  }

  const servicesOptions = services.map((s: Service) => <Option key={s.sid} value={s.sid}>{`${s.name} [${s.country}]`}</Option>)

  // if (isLoading === false && userInfo.length === 0) {
  //   return <div>No data</div>
  // }

  type DataSourceMessageSplash2Pixel = {
    key: string
    idx: number
  } & CidMessageSplash2

  type DataSourceMessagePixel = {
    key: string
    idx: number
  } & CidMessage

  type DataSourcePaymentPixel = {
    key: string
    idx: number
  } & CidOpenFrame

  type DataSourcePixel = {
    key: string
    idx: number
  } & CidSignup

  type DataSourceVisitSplash2Pixel = {
    key: string
    idx: number
  } & CidVisitSplash2

  type DataSourceEncounterPixel = {
    key: string
    idx: number
  } & CidEncounter

  const columnsEncounters: ColumnsType<DataSourceEncounterPixel> = [
    {
      title: '#',
      key: 'idx',
      dataIndex: 'idx',
      width: 80,
      render: (text, record) => record.idx + 1
    },
    {
      title: 'Created',
      dataIndex: 'date_created',
      key: 'date_created',

      render: (dt: number) => (dt ? format(new Date(dt), 'yyyy-MM-dd HH:mm:ss') : null)
    },
    {
      title: 'From',
      dataIndex: 'fromId',
      key: 'fromId',

      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{text}</div>
            <div style={{ marginLeft: '8px' }}>{record.isAngelFrom ? ' 😇' : ' 👨🏻'}</div>
          </div>
        )
      }
    },
    {
      title: 'To',
      dataIndex: 'toId',
      key: 'toId',

      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{text}</div>
            <div style={{ marginLeft: '8px' }}>{record.isAngelTo ? ' 😇' : ' 👨🏻'}</div>
          </div>
        )
      }
    },
    {
      title: 'Match',
      dataIndex: 'is_match',
      key: 'is_match',

      render: (text, record) => (record.is_match ? <CheckOutlined /> : null)
    }
  ]

  const columnsVisitsSplash2: ColumnsType<DataSourceVisitSplash2Pixel> = [
    {
      title: '#',
      key: 'idx',
      dataIndex: 'idx',
      width: 80,
      render: (text, record) => record.idx + 1
    },
    {
      title: 'Created',
      dataIndex: 'date_created',
      key: 'date_created',

      render: (dt: number) => (dt ? format(new Date(dt), 'yyyy-MM-dd HH:mm:ss') : null)
    },
    {
      title: 'From',
      dataIndex: 'fromId',
      key: 'fromId',

      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{text}</div>
            <div style={{ marginLeft: '8px' }}>{record.isAngelFrom ? ' 😇' : ' 👨🏻'}</div>
          </div>
        )
      }
    },
    {
      title: 'To',
      dataIndex: 'toId',
      key: 'toId',

      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{text}</div>
            <div style={{ marginLeft: '8px' }}>{record.isAngelTo ? ' 😇' : ' 👨🏻'}</div>
          </div>
        )
      }
    }
  ]

  const columnsMessages: ColumnsType<DataSourceMessagePixel | DataSourceMessageSplash2Pixel> = [
    {
      title: '#',
      key: 'idx',
      dataIndex: 'idx',
      width: 80,
      render: (text, record) => record.idx + 1
    },
    {
      title: 'Created',
      dataIndex: 'date_created',
      key: 'date_created',

      render: (dt: number) => (dt ? format(new Date(dt), 'yyyy-MM-dd HH:mm:ss') : null)
    },
    {
      title: 'From',
      dataIndex: 'fromId',
      key: 'fromId',

      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{text}</div>
            <div style={{ marginLeft: '8px' }}>{record.isAngelFrom ? ' 😇' : ' 👨🏻'}</div>
          </div>
        )
      }
    },
    {
      title: 'To',
      dataIndex: 'toId',
      key: 'toId',

      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{text}</div>
            <div style={{ marginLeft: '8px' }}>{record.isAngelTo ? ' 😇' : ' 👨🏻'}</div>
          </div>
        )
      }
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',

      render: (text, record) => record.message['message']
    }
  ]

  const columnsPayments: ColumnsType<DataSourcePaymentPixel> = [
    {
      title: '#',
      key: 'idx',
      dataIndex: 'idx',
      render: (text, record) => record.idx + 1
    },
    {
      title: 'Created',
      dataIndex: 'date_created',
      key: 'date_created',
      render: (dt: number) => (dt ? format(new Date(dt), 'yyyy-MM-dd HH:mm:ss') : null)
    },
    {
      title: 'Red',
      dataIndex: 'red',
      key: 'red'
    },
    {
      title: 'Mid',
      dataIndex: 'mid',
      key: 'mid'
    },
    {
      title: 'Rateplan',
      dataIndex: 'ratePlanId',
      key: 'ratePlanId',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div>{text}</div>

          <Tooltip placement="left" title={<div>{record.ratePlanName}</div>}>
            <div>
              &nbsp; &nbsp;
              <EyeOutlined />
            </div>
          </Tooltip>
        </div>
      )
    },
    {
      title: '3DS',
      dataIndex: 'flow3ds',
      key: 'flow3ds',
      render: (text, record) => <span>{record.flow3ds ? 'YES' : 'NO'}</span>
    },
    {
      title: 'FullCC',
      dataIndex: 'flowFullCC',
      key: 'flowFullCC',
      render: (text, record) => <span>{record.flowFullCC ? 'YES' : 'NO'}</span>
    },
    {
      title: 'Pci',
      dataIndex: 'flowPci',
      key: 'flowPci',
      render: (text, record) => <span>{record.flowPci ? 'YES' : 'NO'}</span>
    },
    {
      title: 'Status',
      dataIndex: 'statusCode',
      key: 'statusCode',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div>{text}</div>

          {record.statusDescription && (
            <Tooltip placement="left" title={<div>{record.statusDescription}</div>}>
              <div>
                &nbsp; &nbsp;
                <EyeOutlined />
              </div>
            </Tooltip>
          )}
        </div>
      )
    }
  ]

  const columns: ColumnsType<DataSourcePixel> = [
    {
      title: '#',
      key: 'idx',
      dataIndex: 'idx',
      render: (text, record) => record.idx + 1
    },

    {
      title: 'Created',
      dataIndex: 'date_created',
      key: 'date_created',
      render: (dt: number) => (dt ? format(new Date(dt), 'yyyy-MM-dd HH:mm:ss') : null)
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source'
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text, record) => {
        const k = Object.keys(record)
        const title = k.map((t: string) => (
          <Row key={t}>
            <Col xs={12}>{t}</Col>
            <Col xs={12} style={{ textAlign: 'right' }}>
              {record[t]}
            </Col>
          </Row>
        ))
        return (
          <div>
            <Tooltip placement="left" title={<div>{title}</div>}>
              <EyeOutlined /> View
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const dataSource =
    userInfo && userInfo.infoSignup
      ? userInfo.infoSignup.signup.map((p: CidSignup, idx: number) => {
          return {
            key: `px-item-${idx}`,
            idx,
            ...p
          }
        })
      : []

  const dataSourceMessagesSplash2 =
    userInfo && userInfo.infoActions && userInfo.infoActions.message_splash2
      ? userInfo.infoActions.message_splash2.map((p: CidMessageSplash2, idx: number) => {
          return {
            key: `px-msg-sp2-${idx}`,
            idx,
            ...p
          }
        })
      : []

  const dataSourceMessages =
    userInfo && userInfo.infoActions && userInfo.infoActions.message
      ? userInfo.infoActions.message.map((p: CidMessage, idx: number) => {
          return {
            key: `px-msg-${idx}`,
            idx,

            ...p,
            message: p.message.message,
            fromId: p.message.fromId,
            toId: p.message.toId
          }
        })
      : []

  const datasourceVisitsSplash2 =
    userInfo && userInfo.infoActions && userInfo.infoActions.visits_splash2
      ? userInfo.infoActions.visits_splash2.map((p: CidVisitSplash2, idx: number) => {
          return {
            key: `px-vis-sp2-${idx}`,
            idx,
            ...p
          }
        })
      : []

  const datasourceVisits =
    userInfo && userInfo.infoActions && userInfo.infoActions.visits
      ? userInfo.infoActions.visits.map((p: CidPixelBase, idx: number) => {
          return {
            key: `px-vis-${idx}`,
            idx,
            ...p
          }
        })
      : []

  const datasourcePayments =
    userInfo && userInfo.infoPayments && userInfo.infoPayments.openFrame
      ? userInfo.infoPayments.openFrame.map((p: CidOpenFrame, idx: number) => {
          return {
            key: `px-pay-${idx}`,
            idx,
            ...p
          }
        })
      : []

  const datasourceEncounters =
    userInfo && userInfo.infoActions && userInfo.infoActions.encounter
      ? userInfo.infoActions.encounter.map((p: CidEncounter, idx: number) => {
          return {
            key: `px-enc-${idx}`,
            idx,
            ...p
          }
        })
      : []

  return (
    <AppLayout>
      <KpiTitle size="XL">Search User</KpiTitle>
      <br />
      <br />
      <Form layout="inline" form={form} initialValues={{ sid: null }} onFinish={onFinish}>
        <Form.Item name="sid" label="Service" rules={[{ required: false }]}>
          <Select style={{ width: '245px' }} defaultActiveFirstOption={true}>
            <Option key="all-service" value={null}>
              TUTTI
            </Option>
            {servicesOptions}
          </Select>
        </Form.Item>
        <Form.Item name="cid" label="Cid" rules={[{ required: true }]}>
          <Input placeholder="please insert Cid" />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Divider />

      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px', color: 'white' }}>
          <Spin size="large" tip="Loading..." />
        </div>
      )}

      {userInfo ? (
        <div key={2}>
          {/* <pre>{JSON.stringify(userInfo.infoFlowSignup.media_post_splash2, null, 2)}</pre>
          <pre>{JSON.stringify(userInfo.infoSignup.signup, null, 2)}</pre> */}

          <Row gutter={4}>
            <Col sm={24} md={6}>
              {userInfo && userInfo.infoFlowSignup && (
                <>
                  <Timeline mode="alternate">
                    <Timeline.Item>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div>PRE SIGNUP</div>
                        <div style={{ fontSize: '12px' }}>{userInfo.infoFlowSignup.pixelData[0].time_presignup}</div>
                      </div>
                    </Timeline.Item>
                    {userInfo.infoFlowSignup.media_pre_post > 0 ? (
                      <>
                        <Timeline.Item color={userInfo.infoFlowSignup.media_pre_post > 3 ? 'red' : 'green'} dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                          {writeSeconds(userInfo.infoFlowSignup.media_pre_post)}
                        </Timeline.Item>
                        <Timeline.Item>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div>POST SIGNUP</div>
                            <div style={{ fontSize: '12px' }}>{userInfo.infoFlowSignup.pixelData[0].time_postsignup}</div>
                          </div>
                        </Timeline.Item>
                        <Timeline.Item color={userInfo.infoFlowSignup.media_post_splash2 > 3 ? 'red' : 'green'} dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                          {writeSeconds(userInfo.infoFlowSignup.media_post_splash2)}
                        </Timeline.Item>
                        <Timeline.Item>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div>SPLASH2</div>
                            <div style={{ fontSize: '12px' }}>{userInfo.infoFlowSignup.pixelData[0].time_splash2}</div>
                          </div>
                        </Timeline.Item>
                      </>
                    ) : (
                      <>
                        <Timeline.Item color={userInfo.infoFlowSignup.media_totale > 3 ? 'red' : 'green'} dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                          {writeSeconds(userInfo.infoFlowSignup.media_totale)}
                        </Timeline.Item>
                        <Timeline.Item>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div>SPLASH2</div>
                            <div style={{ fontSize: '12px' }}>{userInfo.infoFlowSignup.pixelData[0].time_splash2}</div>
                          </div>
                        </Timeline.Item>
                      </>
                    )}
                  </Timeline>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '10px' }}>
                    <Space>
                      <span style={{ fontWeight: 'bold', color: '#777' }}>MEDIA TOTALE: </span>
                      <span style={{ color: 'white' }}>{writeSeconds(userInfo.infoFlowSignup.media_totale)} </span>
                    </Space>
                  </div>
                </>
              )}
            </Col>
            <Col sm={24} md={18}>
              <PageHeader className="site-page-header" title="User Signup" />
              <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 10 }} />
            </Col>
          </Row>

          <TableDetails title="User Payments" ds={datasourcePayments} col={columnsPayments} />
          <TableDetails title="User Messages Splash2" ds={dataSourceMessagesSplash2} col={columnsMessages} />
          <TableDetails title="User Visits Splash2" ds={datasourceVisitsSplash2} col={columnsVisitsSplash2} />
          <TableDetails title="User Messages FE2" ds={dataSourceMessages} col={columnsMessages} />
          <TableDetails title="User Visits FE2" ds={datasourceVisits} col={columnsVisitsSplash2} />
          <TableDetails title="User Encounters" ds={datasourceEncounters} col={columnsEncounters} />
        </div>
      ) : (
        <Empty />
      )}
    </AppLayout>
  )
}
interface TableDetailsProps {
  title: string
  ds: any[]
  col: ColumnsType<any>
}

const TableDetails = ({ title, ds, col }: TableDetailsProps) => {
  return (
    <>
      <br />
      <br />
      <Row>
        <Col xs={24}>
          <PageHeader className="site-page-header" title={title} />
          <Table dataSource={ds} columns={col} />
        </Col>
      </Row>
    </>
  )
}

import { useContext, useEffect, useState } from 'react'
import { Checkbox, Divider } from 'antd'

import { GlobalContext } from '../../context/GlobalState'
import styled from 'styled-components'
import { SectionActions } from '../../actions/sections'
import { BASE_SECTIONS } from '../../App'

const CheckboxGroup = Checkbox.Group

const StyledCheckboxGroup = styled(CheckboxGroup)`
  .ant-checkbox-wrapper.ant-checkbox-group-item {
    display: flex;
  }

  .ant-checkbox-wrapper {
    display: block;
    margin-right: 0;
  }
`

export const SectionSelector = () => {
  //   const [sections, setSections] = useState<string[]>([])

  const { state, dispatch } = useContext(GlobalContext)

  const { sections } = state

  const [checkedList, setCheckedList] = useState<string[]>([])
  const [plainOptions, setPlainOptions] = useState<string[]>([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  useEffect(() => {
    setPlainOptions(BASE_SECTIONS)
    setCheckedList(sections)
    if (sections.length === BASE_SECTIONS.length) {
      setCheckAll(true)
      setIndeterminate(false)
    }
  }, [dispatch, sections])

  const onChange = (list) => {
    setCheckedList(list)
    dispatch(SectionActions.setSections(list))
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e) => {
    e.stopPropagation()
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
    dispatch(SectionActions.setSections(e.target.checked ? BASE_SECTIONS : []))
  }

  return (
    <div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <Divider />
      <StyledCheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
    </div>
  )
}

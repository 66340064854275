import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import { Button, DatePicker, Select, Space, Table, Timeline, Tooltip } from 'antd'
import { differenceInMinutes, format } from 'date-fns'
import moment, { Moment } from 'moment'
import { useContext, useEffect, useState } from 'react'
import { PixelActions } from '../../actions/pixel'
import { AppLayout } from '../../components/Layout/AppLayout'
import { GlobalContext } from '../../context/GlobalState'
import { IupayStatus, TitleBox } from '../DashboardHistory/DashboardHistory'
import { getPixels } from '../../api/pixel'
import { PixelItem, Service } from '../../context/types'
import { ColumnsType } from 'antd/lib/table'
import ClockCircleOutlined from '@ant-design/icons/lib/icons/ClockCircleOutlined'
import { writeSeconds } from '../../utils/write-seconds'

const { Option } = Select

export const PixelPage = () => {
  const { state, dispatch } = useContext(GlobalContext)
  const { pixelStartDate, pixels, services, pixelSid } = state
  const [startDate, setStartDate] = useState<string | null>(pixelStartDate)

  // useEffect(() => {
  //   let ti = undefined
  //   async function loadIupayPixelData() {
  //     try {
  //       const resp = await getIupayLastPixel()
  //       dispatch(DashboradActions.setIupayLastPixel(new Date(resp.data)))
  //               const respPing = await getLastPing()
  //               dispatch(DashboradActions.setPingError(respPing.data))
  //     } catch (error) {
  //       //
  //     }
  //   }
  //   ti = setInterval(loadIupayPixelData, 60000)
  //   loadIupayPixelData()
  //   return () => {
  //     if (ti) clearInterval(ti)
  //   }
  // }, [dispatch])

  useEffect(() => {
    async function loadAllData() {
      try {
        getPixels(pixelSid, pixelStartDate).then((resp) => {
          if (resp.status === 200) {
            dispatch(PixelActions.setPixels(resp.data))
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (pixelStartDate !== null) loadAllData()
  }, [dispatch, pixelStartDate, pixelSid])

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  const setLocalDate = (val: Moment) => {
    if (!val || val === null) {
      setStartDate(null)
    } else {
      setStartDate(val.format('yyyy-MM-DD'))
    }
  }

  const setGlobalDate = () => {
    if (startDate === null) {
      dispatch(PixelActions.setPixels(null))
    } else {
      dispatch(PixelActions.setPixelStartDate(startDate))
    }
  }

  type DataSourcePixel = {
    key: string
    idx: number
    cid: string
    time_presignup: string
    time_postsignup: string
    time_splash2: string
    time_pre_post: number
    time_post_splash2: number
    time_total: number
  }

  const dataSource = pixels
    ? pixels.pixelData.map((p: PixelItem, idx: number) => {
        return {
          key: `px-item-101010${idx}`,
          idx,
          ...p
        }
      })
    : []

  const columns: ColumnsType = [
    {
      title: '#',
      key: 'idx',
      dataIndex: 'idx',
      render: (text, record: DataSourcePixel) => record.idx + 1
    },
    {
      title: 'CID',
      dataIndex: 'cid',
      key: 'cid'
    },
    {
      title: 'Presignup',
      dataIndex: 'time_presignup',
      key: 'time_presignup'
    },
    {
      title: 'Time Pre-Post',
      align: 'right',
      dataIndex: 'time_pre_post',
      key: 'time_pre_post',
      width: 160,
      render: (text: string) => <div>{`${text} sec.`}</div>
    },
    {
      title: 'Post Signup',
      dataIndex: 'time_postsignup',
      key: 'time_postsignup'
    },
    {
      title: 'Time Post-Splash2',
      align: 'right',
      dataIndex: 'time_post_splash2',
      key: 'time_post_splash2',
      width: 160,
      render: (text: string) => <div>{`${text} sec.`}</div>
    },
    {
      title: 'Splash2',
      dataIndex: 'time_splash2',
      key: 'time_splash2'
    },
    {
      title: 'Total time',
      width: 160,
      align: 'right',
      dataIndex: 'time_total',
      key: 'time_total',
      render: (text: string) => <div>{`${text} sec.`}</div>
      //   render: (text: string) => (
      //     <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
      //       <div>{`${text} sec.`}</div>
      //     </div>
      //   )
    }
  ]

  return (
    <AppLayout>
      <TitleBox>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>SIGNUP PIXELS</h2>
          <div style={{ marginLeft: '24px', display: 'flex', alignItems: 'center' }}>
            <div>IUPAY status:</div>
            <Tooltip placement="bottom" title={'ultimo pixel: ' + format(state.iupayLastPixel, 'yyy-MM-dd HH:mm:ss')}>
              <IupayStatus $success={differenceInMinutes(new Date(), state.iupayLastPixel) < 5} />
            </Tooltip>
          </div>
        </div>
        <div>
          <Select value={pixelSid} onChange={(v) => dispatch(PixelActions.setPixelSid(v))} style={{ width: '200px', marginRight: '20px' }}>
            {services.map((s: Service) => (
              <Option key={s.sid} value={s.sid}>
                <img src={`/images/${s.country}.png`} alt={s.country} width="16px" height="11px" style={{ opacity: 0.8, marginRight: '4px' }} />
                {s.name}
              </Option>
            ))}
          </Select>

          <Space>
            <DatePicker disabledDate={disabledDate} value={startDate !== null ? moment(startDate) : null} onChange={(val) => setLocalDate(val)} />

            <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => setGlobalDate()} />
          </Space>
        </div>
        <div></div>
        {/* <BtnSelectServices panel={<SectionSelector />} styled={false} title="Sections" /> */}
      </TitleBox>
      {pixels && pixels.media_pre_post > 0 && (
        <>
          <Timeline mode="alternate">
            <Timeline.Item>PRE SIGNUP</Timeline.Item>
            <Timeline.Item color={pixels.media_pre_post > 3 ? 'red' : 'green'} dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
              {writeSeconds(pixels.media_pre_post)}
            </Timeline.Item>
            <Timeline.Item>POST SIGNUP</Timeline.Item>
            <Timeline.Item color={pixels.media_post_splash2 > 3 ? 'red' : 'green'} dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
              {writeSeconds(pixels.media_post_splash2)}
            </Timeline.Item>
            <Timeline.Item>SPLASH2</Timeline.Item>
          </Timeline>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '10px' }}>
            <Space>
              <span style={{ fontWeight: 'bold', color: '#777' }}>MEDIA TOTALE: </span>
              <span style={{ color: 'white' }}>{writeSeconds(pixels.media_totale)} </span>
            </Space>
          </div>
        </>
      )}

      <Table dataSource={dataSource} columns={columns} />
    </AppLayout>
  )
}

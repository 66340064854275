import { createContext, useReducer } from 'react'
import { ActionType, reducer } from './AppReducer'
import { ChatResponse, ConversionRate, DebugDataCollection, KpiHistoryNotAdmin, RootState, ServiceKpis } from './types'

const initialState: RootState = {
  username: '',
  role: 'mkt',
  isLogged: false,
  sockets: new Map<number, ChatResponse>(),
  services: [],
  kpiSettings: [],
  kpiInfo: { functions: [], kpi: [] },
  countries: [],
  serviceKpis: new Map<number, ServiceKpis>(),
  serviceKpisNotAdmin: new Map<number, KpiHistoryNotAdmin>(),
  conversionRates: new Map<number, ConversionRate[]>(),
  crServices: [],
  liveChatServices: [],
  searchKpi: '',
  filterOrHighlightKpi: 'highlight',
  filteredKpis: [],
  liveKpiServices: [],
  sections: [],
  rt_hours: 0,
  slectedCategories: [],
  isLoadingKpis: new Map<number, boolean>(),
  isLoadingConversionRates: new Map<number, boolean>(),
  iupayLastPixel: new Date(),
  dashboardStartDate: null,
  dashboardEndDate: null,
  debugStartDate: null,
  debugEndDate: null,
  debugSid: 1,
  debugData: {
    lastPingIUPay: '',
    mediaDiffPostsignupToSplash2: '',
    mediaDiffPresignupToPostsignup: '',
    totalCidNotEmptyPostSignupToSplash2: 0,
    date: { date_start: '', date_end: '' },
    totalPixelUnique: { presignup: 0, postsignup: 0, signup: 0, splash2: 0 },
    totalPixelUniqueViewFrameFromSignup: { data: 0, description: '' },
    totalPremiumLoginEmail: { data: 0, description: '', cid: [] },
    totalRadarRealDistinct: { data: 0, description: '' }
  },
  debugCollections: new Map<string, DebugDataCollection>(),
  pixels: {
    pixelData: [],
    risultati_totali: 0,
    media_post_splash2: 0,
    media_pre_post: 0,
    media_totale: 0
  },
  pixelStartDate: null,
  pixelSid: 1,
  pingError: {
    mysql: true,
    mongo: true,
    logs: true,
    live: []
  }
}

// Create context
export const GlobalContext = createContext<{
  state: typeof initialState
  dispatch: (action: ActionType) => void
}>({
  state: initialState,
  dispatch: () => {}
})

export const GlobalConsumer = GlobalContext.Consumer

// Provider component
export const GlobalProvider = (props: any) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

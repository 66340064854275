import { ActionType } from '../context/AppReducer'
import { DebugData } from '../context/types'

export const SET_SID = 'debug/SET_SID'
export const SET_DATA = 'debug/SET_DATA'
export const SET_FILTER_DATES = 'debug/SET_FILTER_DATES'

export const DebugdActions = {
  setFilterDates: (startDate: string, endDate: string): ActionType => {
    return {
      type: SET_FILTER_DATES,
      payload: { startDate, endDate }
    }
  },
  setDebugData: (debugData: DebugData): ActionType => {
    // console.log(debugData)
    return {
      type: SET_DATA,
      payload: debugData
    }
  },
  setDebugSid: (sid: number): ActionType => {
    return {
      type: SET_SID,
      payload: sid
    }
  }
}

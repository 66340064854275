import { ActionType } from '../context/AppReducer'
import { PixelItem } from '../context/types'

export const SET_PIXELS = 'pixel/SET_PIXELS'
export const SET_START_DATE = 'pixel/SET_START_DATE'
export const SET_SID = 'pixel/SET_SID'

export const PixelActions = {
  setPixels: (pixelItems: PixelItem[]): ActionType => {
    return {
      type: SET_PIXELS,
      payload: pixelItems
    }
  },
  setPixelStartDate: (startDate: string): ActionType => {
    return {
      type: SET_START_DATE,
      payload: startDate
    }
  },
  setPixelSid: (sid: number): ActionType => {
    return {
      type: SET_SID,
      payload: sid
    }
  }
}
